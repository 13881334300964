<template>
  <div
    id="app"
    class="app"
    :class="{
      'asm-mode-active': asmInfo.Active,
      'disable-ui-animations': disableUIAnimations,
      'sub-content-active': subContentOpen,
      'enable-helpers': enableHelpers,
      'global-notification-enabled': globalNotification,
    }"
  >
    <div id="main-navigation" class="main-navigation-portal"></div>
    <div id="custom-datepicker-teleport" class="custom-datepicker"></div>
    <AsmChecker />
    <AdobeTargetEventHandler />
    <LockScroll />
    <InactivityTimer v-if="isAuthenticated && inactivityTimerEnabled" />
    <SessionTimer v-if="isAuthenticated" />
    <GlobalNotification />
    <router-view class="router-view" />
    <AssistantContainer />
    <Notification />
    <Modal />
    <SplashScreen />
    <ContentOverlay />
  </div>
</template>
<script setup lang="ts">
import AssistantContainer from '@/src/core/components/assistant/assistant-container/assistant-container.vue';
import Modal from '@/src/core/components/modals/modal.vue';
import GlobalNotification from '@/src/core/components/ui/global-notification/global-notification.vue';
import Notification from '@/src/core/components/ui/notification/notification.vue';
import SplashScreen from '@/src/core/components/ui/splash-screen/splash-screen.vue';
import AdobeTargetEventHandler from '@/src/core/components/util/adobe-target-event-handler/adobe-target-event-handler.vue';
import AsmChecker from '@/src/core/components/util/asm-checker/asm-checker.vue';
import ContentOverlay from '@/src/core/components/util/content-overlay/content-overlay.vue';
import InactivityTimer from '@/src/core/components/util/inactivity-timer/inactivity-timer.vue';
import LockScroll from '@/src/core/components/util/lock-scroll/lock-scroll.vue';
import SessionTimer from '@/src/core/components/util/session-timer/session-timer.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { RouteLocationNormalized, START_LOCATION, useRoute } from 'vue-router';
import { getEnv } from './core/services/environment';
import { FEATURES, hasFeature } from './core/services/features';
import { useAuthenticationStore } from './core/stores/authentication';
import { useGlobalNotificationStore } from './core/stores/global-notification';
import { useLinksStore } from './core/stores/links';
import { useUIStore } from './core/stores/ui';
import { IWindowServerRendered } from './core/types/ui';
import { ResizeEvent, ResizeEventBus, ResizeEventSizes } from './core/utils/resize-event-bus';
import { ScrollEvent, ScrollEventBus } from './core/utils/scroll-event-bus';

import '@/src/core/services/environment';
import 'scrollmonitor';
import './style.scss';
import { adobeTargetTriggerView } from './core/utils/adobe-target';
import { debounce } from 'lodash';
import { useAdobeLaunchTracking } from './core/services/adobelaunchtracking';
import { useContentStore } from './content/stores/content';

const authenticationStore = useAuthenticationStore();
const globalNotificationStore = useGlobalNotificationStore();
const linksStore = useLinksStore();
const uiStore = useUIStore();
const contentStore = useContentStore();
const disableUIAnimations = ref(false);
const enableHelpers = ref(false);

const subContentOpen = computed(() => uiStore.subContentOpen);
const globalNotification = computed(() => globalNotificationStore.notification.Body);
const inactivityTimerEnabled = computed(() => hasFeature(FEATURES.INACTIVITY_LOGOUT_TIMER));
const asmInfo = computed(() => authenticationStore.asm);
const isAuthenticated = computed(() => authenticationStore.isAuthenticated);
const route = useRoute();

// Watchers

// Functions
const onResize = ($event: Event) => {
  const windowSize: ResizeEventSizes = {
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
  };
  ResizeEventBus.$emit('resize', { windowSize, originalEvent: $event } as unknown as ResizeEvent);
};

const onScroll = ($event: Event) => {
  const scrollPosition: number = window.scrollY || window.pageYOffset;
  ScrollEventBus.$emit('scroll', { scrollPosition, originalEvent: $event } as ScrollEvent);
};

// TODO: We should not be lisening to the whole router object
// watch a more specific route attribute instead
watch(route, (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (to.name) {
    uiStore.setNavigationDropdownId({ id: null });
  }

  const path: string = to.fullPath;
  const lastVisitedPage = contentStore.lastVisitedPageUrl;
  adobeTargetTriggerView(path.length > 0 ? path : 'frontpage', { page: true });
  const debounceFunction = debounce(
    (reload = false) => useAdobeLaunchTracking().pageView(to, lastVisitedPage, to.meta!, reload),
    1000,
  );

  if (from === START_LOCATION) {
    debounceFunction(true);
  } else {
    debounceFunction();
  }

  contentStore.setLastVisitedPage({ path: to.fullPath });
});

onMounted(() => {
  // Equivalent of beforeCreate
  const srProduct = document.querySelectorAll('[sr-product]');
  const srContent = document.querySelectorAll('[sr-content]');
  const srSeller = document.querySelectorAll('[sr-seller]');
  const srData = document.querySelectorAll('[sr-data]');

  if (srProduct.length > 0) {
    (window as unknown as IWindowServerRendered).srProduct = srProduct[0] as HTMLElement;
  }

  if (srSeller.length > 0) {
    (window as unknown as IWindowServerRendered).srSeller = srSeller[0] as HTMLElement;
  }

  if (srContent.length > 0) {
    const srSection = srContent[0].querySelectorAll('[sr-section]');
    (window as unknown as IWindowServerRendered).srContent =
      (srSection.length > 0 && (srSection[0] as HTMLElement)) || (srContent[0] as HTMLElement);
  }

  if (srData.length > 0) {
    const dataString = srData[0].getAttribute('sr-data');
    (window as unknown as IWindowServerRendered).srData = dataString && JSON.parse(dataString);
  }

  // Equivalent of created
  if (authenticationStore.hasStorageToken) {
    authenticationStore.refreshLogin();
  }

  if (getEnv('VUE_APP_UITEST') && window.URLSearchParams) {
    const urlParam = new URLSearchParams(window.location.search);
    const uiTestQuery = urlParam.get('ui-test');
    enableHelpers.value = urlParam.get('enable-helpers') !== null;

    if (uiTestQuery) {
      uiStore.setDisableUIAnimations({ disable: true });
      disableUIAnimations.value = true;
    }
  }

  // Equivalent of mounted
  linksStore.fetchLinks();
  window.addEventListener('scroll', onScroll);
  window.addEventListener('resize', onResize);
});
</script>
