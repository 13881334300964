import { MarketplaceMessageAttachmentInfo } from './MarketplaceMessageAttachmentInfo';

export class CoreQuoteMessage {
  Body: string;
  CreatedDate: string;
  FromType: string;
  Id: string;
  ToType: string;
  AuthorName: string;
  AuthorType: string;
  AttachmentInfos: MarketplaceMessageAttachmentInfo[];
  MasterShopName: string;
  LocationDisplayName: string;

  constructor(init?: Partial<CoreQuoteMessage>) {
    Object.assign(this, init);

    const authorLower = this.FromType.toLocaleLowerCase();
    this.AuthorName =
      authorLower.charAt(0).toUpperCase() + authorLower.slice(1).toLocaleLowerCase();
    this.AuthorType = this.FromType.toUpperCase() + '_USER';
  }
}
