import { HybrisMarketplaceMessagingThread } from './HybrisMarketplaceMessagingThread';

export class HybrisMarketplaceMessagingThreads {
  orderCode?: string;
  threads: HybrisMarketplaceMessagingThread[];

  constructor(init?: Partial<HybrisMarketplaceMessagingThreads>) {
    Object.assign(this, init);
  }
}
