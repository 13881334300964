<template>
  <VSimpleTable class="product-details-lease-table-price" :headers="['Pricing details', '']">
    <tbody>
      <VSimpleTableRow v-ui-test="'product-document-infos'">
        <VSimpleTableCell :style="{ width: '100%' }">Unit price (sales new)</VSimpleTableCell>
        <VSimpleTableCell>
          <span class="product-details-lease-table-price__cell">
            {{ leaseInfo?.unitPrice ? `${currency} ${formatPrice(leaseInfo?.unitPrice)}` : 'N/A' }}
          </span>
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow v-ui-test="'product-document-infos'">
        <VSimpleTableCell :style="{ width: '100%' }">Minimum lease period</VSimpleTableCell>
        <VSimpleTableCell>
          <span class="product-details-lease-table-price__cell">{{
            leaseInfo?.minimumLease?.numberOfDays
              ? `${leaseInfo?.minimumLease?.numberOfDays} days`
              : 'N/A'
          }}</span>
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow v-ui-test="'product-document-infos'">
        <VSimpleTableCell :style="{ width: '100%' }">Minimum lease charges</VSimpleTableCell>
        <VSimpleTableCell>
          <span class="product-details-lease-table-price__cell"
            >{{
              leaseInfo?.minimumLease?.subtotal
                ? `${currency} ${formatPrice(leaseInfo.minimumLease.subtotal)}`
                : 'N/A'
            }}
            <Tooltip
              v-if="leaseInfo?.minimumLease?.subtotal"
              :position="TooltipDefaultPosition.Top"
            >
              <span class="banner"> <svgicon name="ui-info" /></span>
              <template #tooltip-content>
                {{ minimumLeasePeriodText }}
              </template>
            </Tooltip>
          </span>
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow v-ui-test="'product-document-infos'">
        <VSimpleTableCell :style="{ width: '100%' }">Additional charges per day</VSimpleTableCell>
        <VSimpleTableCell>
          <span class="product-details-lease-table-price__cell">
            {{
              leaseInfo?.additionalLease?.chargesPerDay
                ? `${currency} ${formatPrice(leaseInfo.additionalLease.chargesPerDay)}`
                : 'N/A'
            }}
            <Tooltip
              v-if="leaseInfo?.additionalLease?.chargesPerDay"
              :position="TooltipDefaultPosition.Top"
            >
              <span class="banner"> <svgicon name="ui-info" /></span>
              <template #tooltip-content>
                {{ additionalLeaseChargeText }}
              </template>
            </Tooltip>
          </span>
        </VSimpleTableCell>
      </VSimpleTableRow>
      <VSimpleTableRow v-ui-test="'product-document-infos'">
        <VSimpleTableCell :style="{ width: '100%' }">Lease handling fee</VSimpleTableCell>
        <VSimpleTableCell>
          <span class="product-details-lease-table-price__cell">{{
            leaseInfo?.handlingFee ? `${currency} ${formatPrice(leaseInfo?.handlingFee)}` : 'N/A'
          }}</span>
        </VSimpleTableCell>
      </VSimpleTableRow>
    </tbody>
  </VSimpleTable>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { LeaseInfo } from '@/src/core/apim';
import VSimpleTable from '@/src/core/components/ui/simple-table/simple-table.vue';
import VSimpleTableCell from '@/src/core/components/ui/simple-table/table-cell/table-cell.vue';
import VSimpleTableRow from '@/src/core/components/ui/simple-table/table-row/table-row.vue';
import Tooltip from '@/src/core/components/ui/tooltip/tooltip.vue';
import useText from '@/src/core/hooks/useText';
import { Product } from '@/src/core/types/api';
import { TooltipDefaultPosition } from '@/src/core/types/interfaces';
import { formatPrice } from '@/src/core/utils/math';

const textK = useText();
const props = defineProps<{
  leaseInfo?: LeaseInfo;
  product: Product;
}>();

const leaseInfo = computed(() => {
  return props.leaseInfo;
});

const currency = computed(() => {
  return props.leaseInfo?.currency;
});

const minimumLeasePeriodText = computed(() => {
  if (props.product.AirbusProprietary) {
    return textK('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_PARTS');
  }
  return textK('UI_PRODUCT_MINIMUM_LEASE_CHARGE_TEXT_TOOL').replace(
    /\[\[minimum-lease-charge-percent\]\]/gi,
    leaseInfo.value?.minimumLease?.percentagePerDay as string,
  );
});

const additionalLeaseChargeText = computed(() => {
  if (props.product.AirbusProprietary) {
    return textK('UI_PRODUCT_ADDITIONAL_DAY_LEASE_CHARGE_PART_TEXT');
  }
  return textK('UI_PRODUCT_ADDITIONAL_DAY_LEASE_CHARGE_TEXT').replace(
    /\[\[additional-lease-charge-percent\]\]/gi,
    leaseInfo?.value?.additionalLease?.percentagePerDay as string,
  );
});
</script>

<style lang="scss" scoped src="./lease-pricing-details-table.scss"></style>
