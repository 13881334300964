import { MessageThreadPagination } from '@/src/core/apim/spec';

export class Pagination implements MessageThreadPagination {
  totalResults: number = 0;
  totalPages: number = 0;
  currentPage: number = 0;

  constructor(init?: Partial<Pagination>) {
    Object.assign(this, init);
  }
}
