import { Price, PriceBreakInfo, ProductPriceEntry, ProductPriceInfo } from '@/src/core/apim';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { getLegacyDateMapping } from '@/src/market/services/legacy-mapping';
import { getProductRuleMapping } from '@/src/market/services/legacy-mapping-product-rule';

export const getPriceWithMapping = (price: Price) => {
  return {
    ...price,
    minQuantity: price?.minQuantity ?? 0,
    maxQuantity: price?.maxQuantity ?? 0,
    infoStatus: price.infoStatus ?? '',
    isAsmHidden: price.infoStatus === 'ASM_HIDDEN',
    volumeDiscountPercentage: price?.volumeDiscountPercentage ?? 0,
  };
};

export const getPriceBreakInfoWithMapping = (priceBreakInfo: PriceBreakInfo) => {
  const { basePrice, minQuantity, maxQuantity } = priceBreakInfo;
  return {
    ...priceBreakInfo,
    minQuantity: minQuantity ?? 0,
    maxQuantity: maxQuantity ?? 0,
    basePrice: basePrice ? getPriceWithMapping(basePrice) : undefined,
  };
};

export const getProductPriceInfoWithMapping = (productPriceInfo: ProductPriceInfo) => {
  const {
    priceBreakInfos,
    price,
    basePrice,
    discountEndDate,
    discountStartDate,
    discountPercentage,
  } = productPriceInfo;

  return {
    ...productPriceInfo,
    basePrice: basePrice ? getPriceWithMapping(basePrice) : undefined,
    price: price ? getPriceWithMapping(price) : undefined,
    priceBreakInfos: priceBreakInfos
      ? priceBreakInfos.map(getPriceBreakInfoWithMapping)
      : undefined,
    discountStartDate: getLegacyDateMapping(discountStartDate),
    discountEndDate: getLegacyDateMapping(discountEndDate),
    discountPercentage: discountPercentage ?? 0,

    // TODO: Remove. This is not provided by the API, it looks static in Umbraco
    slowMoverPriceAvailable: false,
    hasPriceBreaks: false,
  };
};

/**
 * Determines if the discount price is available based on the provided price information.
 *
 * @param priceInfo - The product price information containing discount details.
 * @returns A boolean indicating whether the discount price is currently available.
 */
export const getDiscountPriceAvailable = (priceInfo?: ProductPriceInfo) => {
  if (!priceInfo?.discountPrice) {
    return false;
  }
  if (!priceInfo?.discountStartDate || !priceInfo?.discountEndDate) {
    return false;
  }

  const now = new Date();
  const discountStartDate = new Date(priceInfo.discountStartDate);
  const discountEndDate = new Date(priceInfo.discountEndDate);

  return discountStartDate <= now && discountEndDate > now;
};

export const getProductPriceWithMapping = (productPrice: ProductPriceEntry) => {
  const { productRule } = productPrice;
  const productRuleMapping = getProductRuleMapping(productRule!);

  const showStock = productRuleMapping.showStock;
  const showPrice = productRuleMapping.showPrice;
  const showOrderButton = productRuleMapping.showOrderButton;
  const ruleStatus = productRuleMapping.ruleStatus;
  const slowMoverPriceAvailable = Boolean(productPrice.productPriceInfo?.zproPriceAvailable);
  const discountPriceAvailable = getDiscountPriceAvailable(productPrice.productPriceInfo);

  return objectKeysToPascalCaseRecursive({
    ...productPrice,
    productPriceInfo: getProductPriceInfoWithMapping(productPrice.productPriceInfo!),
    showStock,
    showPrice,
    showOrderButton,
    ruleStatus,

    slowMoverPriceAvailable,
    discountPriceAvailable,

    // TODO: Remove. This is not provided by the API, it looks static in Umbraco
    discountEndDateAvailable: false,
  });
};

export type LegacyPriceInfo = ReturnType<typeof getProductPriceWithMapping>;
