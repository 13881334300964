<template>
  <div class="product__detail" v-if="procurement && serialNumber">
    <p class="product__detail__label">Serial Number</p>
    <p class="product__detail__value">
      {{ serialNumber }}
    </p>
    <SkeletonLoader v-if="!procurement" :size="UiSize.Small" />
  </div>
</template>

<script lang="ts" setup>
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { Procurement } from '@/src/core/types/interfaces';
import { computed } from 'vue';

interface Props {
  procurement?: Procurement;
}

const props = defineProps<Props>();
const procurement = computed(() => props.procurement);
const emit = defineEmits(['serialNumber']);

const serialNumber = computed(() => {
  if (!procurement.value) {
    return null;
  }
  emit('serialNumber', procurement.value?.serialNumber);
  return procurement.value?.serialNumber;
});
</script>
