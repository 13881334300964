<template>
  <transition name="notification-item" @before-enter="beforeEnter" :css="false">
    <div
      class="notification-wrapper"
      v-if="isActive"
      ref="innerElement"
      :class="{ 'animation-running': animationRunning, 'animation-ended': animationEnded }"
    >
      <div class="notification-item-wrapper">
        <div
          class="notification"
          :class="{
            error: type === notificationTypes.Error,
            success: type === notificationTypes.Success,
            info: type === notificationTypes.Information,
            warning: type === notificationTypes.Warning,
          }"
          @mouseover="pauseTimer()"
          @mouseout="continueTimer()"
        >
          <div class="notification__icon" ref="icon">
            <svgicon
              name="ui-notification-error"
              v-if="
                type === notificationTypes.Error ||
                type === notificationTypes.PasswordPolicyViolationError
              "
            ></svgicon>
            <svgicon
              name="ui-notification-success"
              v-if="type === notificationTypes.Success"
            ></svgicon>
            <svgicon
              name="ui-notification-default"
              v-if="type === notificationTypes.Information"
            ></svgicon>
            <svgicon
              name="ui-notification-warning"
              v-if="type === notificationTypes.Warning"
            ></svgicon>
          </div>

          <div class="notification__body" ref="body">
            <div
              class="notification__title"
              v-ui-test="'notification-item-title'"
              v-if="notification.Title"
            >
              {{ notification.Title }}
            </div>
            <div class="notification__copy" v-if="notification.Description">
              {{ notification.Description }}
            </div>
            <div
              class="notification__action"
              v-if="notification.NotificationAction"
              v-ui-test="'notification-item-action'"
              @click="removeNotification"
            >
              <a
                class="address-changer-link"
                v-if="notification.NotificationAction.Modal"
                @click="
                  showModal(
                    notification.NotificationAction.StringOne!,
                    notification.NotificationAction.StringTwo!,
                  )
                "
                >{{ notification.NotificationAction.Name }}</a
              >
              <router-link v-else :to="notification.NotificationAction.Url!">{{
                notification.NotificationAction.Name
              }}</router-link>
            </div>
          </div>
          <div class="notification__close">
            <button
              class="button-invisible"
              aria-label="Remove notification"
              @click="removeNotification"
            >
              <svgicon name="ui-close"></svgicon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" src="./notification-item.scss" scoped></style>

<script lang="ts" setup>
import { useModalStore } from '@/src/core/stores/modal';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { useUIStore } from '@/src/core/stores/ui';
import { NotificationTypes } from '@/src/core/types/api';
import { INotificationItem } from '@/src/core/types/ui';
import { gsap } from 'gsap';
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';

interface Props {
  notification: INotificationItem;
}

const props = defineProps<Props>();

const notificationTypes = ref(NotificationTypes);
const animationRunning = ref(false);
const isActive = ref(false);
const timer = ref(0);
const animationStarted = ref(false);
const animationEnded = ref(false);
const isClosing = ref(false);
const animationDuration = ref(0.5);
const animationEasing = ref('power4.inOut');
const tweenAnimation = gsap.timeline({ paused: true });

// Ref Elements
const innerElement = ref<HTMLElement>();
const icon = ref<HTMLElement>();
const body = ref<HTMLElement>();

const notificationStore = useNotificationsStore();
const uiStore = useUIStore();
const modalStore = useModalStore();

const disableUIAnimations = computed(() => {
  return uiStore.disableAnimations;
});

const type = computed(() => {
  return props.notification.Type;
});

const timing = computed(() => {
  return props.notification.Timing;
});

const removeFromStore = () => {
  notificationStore.removeNotification(props.notification);
};

const removeNotification = () => {
  if (isClosing.value) {
    return;
  }
  animationEnded.value = false;
  isClosing.value = true;

  // Wait for css animation (removing outline-shadow effect)
  setTimeout(() => {
    animationRunning.value = true;
    tweenAnimation.reverse(0);
  }, 150);
};

const startTiming = () => {
  if (timing.value !== undefined) {
    timer.value = window.setTimeout(() => {
      removeNotification();
    }, timing.value);
  }
};

const pauseTimer = () => {
  clearTimeout(timer.value);
};

const continueTimer = () => {
  if (timing.value !== undefined) {
    timer.value = window.setTimeout(() => {
      removeNotification();
    }, timing.value);
  }
};

const setUpAnimation = (el: HTMLElement) => {
  const initialHeight: number | undefined = el.offsetHeight;
  tweenAnimation
    // Set element inital state
    .set(
      el,
      {
        width: 5,
        height: 0,
        marginBottom: 0,
        autoAlpha: animationDuration.value > 0 ? 0 : 1,
      },
      0,
    )
    .add(
      gsap.to(el, {
        duration: animationDuration.value,
        height: initialHeight + 'px',
        marginBottom: 30,
        ease: animationEasing.value,
        autoAlpha: 1,
      }),
    )
    .add(
      gsap.to(el, {
        duration: animationDuration.value,
        width: '100%',
        ease: animationEasing.value,
      }),
    )
    .set(el, {
      clearProps: 'width, height, marginBottom',
    })
    .eventCallback('onStart', () => {
      animationRunning.value = true;
      animationStarted.value = true;
    })
    .eventCallback('onReverseComplete', () => {
      isActive.value = false;
      animationRunning.value = false;
      removeFromStore();

      // Set element end state
      tweenAnimation.set(
        el,
        {
          width: 5,
          height: 0,
          marginBottom: 0,
        },
        0,
      );
    })
    .eventCallback('onComplete', () => {
      animationRunning.value = false;
      nextTick(() => {
        animationEnded.value = true;
      });
    });
};

const beforeEnter = (el: HTMLElement) => {
  nextTick(() => {
    setUpAnimation(el);
    tweenAnimation.play();
  });
};

const showModal = (modalType: string, modalSellerId: string) => {
  modalStore.showModal({
    modalComponent: modalType,
    params: { sellerId: modalSellerId },
  });
};

if (disableUIAnimations.value) {
  animationDuration.value = 0;
}

onMounted(() => {
  startTiming();
  isActive.value = true;
});

onUnmounted(() => {
  tweenAnimation.kill();
});
</script>
