import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { IProductPriceEntry, IProductQueueItem } from '@/src/core/types/interfaces';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ProductApi } from '../api/productApi';

let queueTimer: number;
const loadThreshold: number = 1500;

export const useProductPriceInfoStore = defineStore('product-price-info', () => {
  const products = ref<IProductPriceEntry[]>([]);
  const queue = ref<IProductQueueItem[]>([]);
  const isBusy = ref<boolean | undefined>(false);

  const productQueued = (payload: { OfferId: string; Quantity: number }) => {
    queue.value.push(payload);
  };

  const queueCleared = () => {
    queue.value = [];
  };

  const productBundleUpdated = (payload: {
    priceDataBundle: IProductPriceEntry[];
    failed?: boolean;
  }) => {
    payload.priceDataBundle.map((additionalData: IProductPriceEntry) => {
      const hasFailed: object = payload.failed ? { HasFailed: true } : {};
      const productPriceData = Object.assign(
        {},
        additionalData,
        { IsBusy: false },
        hasFailed,
      ) as IProductPriceEntry;

      if (products.value.length <= 0) {
        products.value.push(productPriceData);
        isBusy.value = productPriceData?.IsBusy;
      } else {
        const productExistOnIndex = products.value.findIndex(
          (product) => product.Id === productPriceData.Id,
        );

        if (productExistOnIndex > -1) {
          products.value.splice(productExistOnIndex, 1, productPriceData);
        } else {
          products.value.push(productPriceData);
        }
      }
    });
  };

  const reset = () => {
    products.value = [];
    queue.value = [];
  };

  const queueAdditionalProductData = async (payload: { OfferId: string; Quantity: number }) => {
    if (!isInPriceProductCue(payload)) {
      clearTimeout(queueTimer);

      const productIndex = products.value.findIndex(
        (product) =>
          product.Id === payload.OfferId /* TODO: is Code = OfferId in ProductEntryBase */,
      );

      if (productIndex > -1) {
        products.value[productIndex].IsBusy = true;
      }
      productQueued(payload);

      // if we have 5 in cue send the request - otherwise start the timer
      if (queue.value.length >= 5) {
        fetchBundle();
        queueCleared();
      } else {
        // make sure we send the request if the user only have 3 products in view and stops scrolling
        queueTimer = window.setTimeout(() => {
          if (queue.value.length > 0) {
            fetchBundle();
            queueCleared();
          }
        }, loadThreshold);
      }
    }
  };

  const fetchBundle = async () => {
    if (queue.value.length === 0) {
      return;
    }
    // make sure we only send requests bundled by 5
    const chunkBy = 5;
    const chunkedProducts = [];
    // Only include if quantity over 0.
    const allProducts = queue.value.filter((cueItem: IProductQueueItem) => cueItem.Quantity > 0);
    const chunks = Math.ceil(allProducts.length / chunkBy);

    for (let i = 0; i < chunks; i++) {
      chunkedProducts.push(allProducts.splice(0, chunkBy));
    }

    for (const productBundle of chunkedProducts) {
      const { IsSuccess, Data } = await Req(
        {
          method: 'POST',
          url: ProductApi.ProductPriceInfo,
          data: { productPriceRequests: productBundle },
        },
        undefined,
        ProductApi.handleProductPriceInfo,
      );

      // Pass data every time — even if error
      // This gives the user fail-feedback
      const success = !!Data && IsSuccess;
      productBundleUpdated({
        priceDataBundle: success ? Data : productBundle,
        failed: !success,
      });
    }
  };

  const fetchProduct = async (payload: { OfferId: string; Quantity: number }) => {
    const { IsSuccess, Data } = await Req(
      {
        method: 'POST',
        url: ProductApi.ProductPriceInfo,
        data: { productPriceRequests: [payload] },
      },
      new ReqQueue(ReqQueueTypes.Default, 'additionalInfoSingle', payload.OfferId),
      ProductApi.handleProductPriceInfo,
    );

    // Pass data every time — even if error
    // This gives the user fail-feedback
    const success = !!Data && IsSuccess;

    productBundleUpdated({
      priceDataBundle: success ? Data : [payload],
      failed: !success,
    });
  };

  const priceDataByOfferId = (id: string) => {
    return products.value.filter((product) => product.Id === id)[0];
  };

  const isInPriceProductCue = (productItem: IProductQueueItem) => {
    return !!queue.value.find((product) => product.OfferId === productItem.OfferId);
  };

  return {
    products,
    queue,
    isBusy,
    reset,
    priceDataByOfferId,
    isInPriceProductCue,
    fetchProduct,
    fetchBundle,
    queueAdditionalProductData,
  };
});
