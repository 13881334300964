// tslint:disable
import { apim } from '@/src/core/apim';
import { getEnv } from '@/src/core/services/environment';
import { SATAIR_BASESITE_ID } from '../settings/baesiteId';
import {
  toLegacyResponse,
  toStandardLegacyResponse,
} from '@/src/core/services/legacy-response-utils';
import { getOfferWithMapping, legacyEmptyOffer } from '../services/legacy-mapping-offer';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { handleErrorResponse } from '../services/error-notification-handling';
import { getProductWithMapping, isOffer } from '../services/legacy-mapping-product';
import { getProductPlantsWithMapping } from '../services/legacy-mapping-product-plants';
import { AxiosRequestConfig } from 'axios';
import { getAdditionalInfoWithMapping } from '../services/legacy-mapping-additional-info';
import { getProductPriceWithMapping } from '../services/legacy-mapping-product-price';
import { getOrderTypeOptionWithMapping } from '../services/legacy-mapping-order-type';
import { toLegacyDownloadResponse } from '@/src/core/services/legacy-download-response';
import { OwningSystem } from '@/src/core/types/api';
import { productIdIsOffer } from '../services/legacy-mapping';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class ProductApi {
  public static Product = (id: string) => `${baseUrl}/api/product/${id}`;
  public static ProductOffer = (id: string) => `${baseUrl}/api/product/offer/${id}`;
  public static async handleProductOffer(id: string) {
    try {
      if (productIdIsOffer(id)) {
        const response = await apim.getOffer(SATAIR_BASESITE_ID, id);
        const offer = response.data;
        const offerWithMapping = getOfferWithMapping(offer);

        return toLegacyResponse(response, {
          Data: objectKeysToPascalCaseRecursive(offerWithMapping),
        });
      } else {
        const response = await apim.getProductById(SATAIR_BASESITE_ID, id);
        const product = response.data;
        const emptyOffer = { ...legacyEmptyOffer, id, product };
        const offerWithMapping = getOfferWithMapping(emptyOffer);

        return toLegacyResponse(response, {
          Data: objectKeysToPascalCaseRecursive(offerWithMapping),
        });
      }
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductByRoute = (cageCode: string, productNumber: string, owningSystem: string) =>
    `${baseUrl}/api/product/lookup/${cageCode}/${productNumber}/${owningSystem}`;
  public static async handleProductByRoute(payload: {
    cageCode: string;
    productNumber: string;
    owningSystem: OwningSystem;
  }) {
    try {
      const { cageCode, productNumber, owningSystem } = payload;
      const response = await apim.lookupProductWithOwningSystem(
        SATAIR_BASESITE_ID,
        cageCode,
        owningSystem,
        productNumber,
      );
      const product = response.data;
      const offerWithMapping = getOfferWithMapping({
        id: product.id,
        product,
      });
      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(offerWithMapping),
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductSku = () => `${baseUrl}/api/product/sku`;
  public static async handleProductSku(sku: string) {
    try {
      const [partNumber, cageCode] = sku.split(':');
      const response = await apim.lookupProduct(SATAIR_BASESITE_ID, cageCode, partNumber);
      const productWithMapping = getProductWithMapping(response.data);
      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(productWithMapping),
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductInfo = (id: string) => `${baseUrl}/api/product/info/${id}`;
  public static async handleProductInfo(id: string) {
    try {
      const response = await apim.getProductById(SATAIR_BASESITE_ID, id, {
        fields: 'FULL',
      });
      const productWithMapping = getProductWithMapping(response.data);
      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(productWithMapping),
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductPlantAvailability = (id: string) => `${baseUrl}/api/product/plants/${id}`;
  public static async handleProductPlantAvailability(id: string) {
    try {
      const response = await apim.plants(
        SATAIR_BASESITE_ID,
        { productEntries: [{ id }] },
        { fields: 'FULL' },
      );
      const plantsWithMapping = getProductPlantsWithMapping(response.data);
      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(plantsWithMapping),
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductAdditionalInfo = `${baseUrl}/api/product/additionalinfo/`;
  public static async handleProductAdditionalInfo(settings: AxiosRequestConfig) {
    try {
      const productPriceRequests = settings.data.productPriceRequests.map(
        (product: { OfferId: any; Quantity: any }) => {
          return {
            id: product.OfferId,
            quantity: product.Quantity,
            warehouseCode: '',
          };
        },
      );

      const response = await apim.getProductAdditionalInfo(
        SATAIR_BASESITE_ID,
        { productEntries: productPriceRequests },
        {
          fields: 'FULL',
        },
      );
      const additionalInfoWithMapping = response.data.productEntries?.map(
        getAdditionalInfoWithMapping,
      );
      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive(additionalInfoWithMapping),
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static AirbusPartRequest = `${baseUrl}/api/product/airbuspartrequest/`;
  public static async handleAirbusPartRequest(settings: AxiosRequestConfig) {
    try {
      const productEntries = settings.data.productPriceRequests.map(
        (product: { OfferId: any; Quantity: any }) => {
          return {
            id: product.OfferId,
            quantity: product.Quantity,
          };
        },
      );

      const response = await apim.getAirbusPartRequest(SATAIR_BASESITE_ID, { productEntries });

      return toStandardLegacyResponse(response);
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductPriceInfo = `${baseUrl}/api/product/priceinfo/`;
  public static async handleProductPriceInfo(settings: AxiosRequestConfig) {
    try {
      const { data } = settings;

      const resp = await apim.getProductPriceInfo('satairb2b', {
        productEntries: data.productPriceRequests.map((entry: any) => ({
          id: entry.OfferId,
          quantity: entry.Quantity,
        })),
      });

      const productPriceEntriesWithMapping = resp.data.productPriceEntries?.map(
        getProductPriceWithMapping,
      );

      return toLegacyResponse(resp, {
        Data: productPriceEntriesWithMapping,
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductCheckoutoptions = `${baseUrl}/api/product/checkoutoptions`;
  public static async handleProductCheckoutOptions() {
    try {
      const response = await apim.getCheckoutOptions(SATAIR_BASESITE_ID);
      const orderTypeOptionsWithMapping = response.data.orderTypes?.map(
        getOrderTypeOptionWithMapping,
      );

      return toLegacyResponse(response, {
        Data: objectKeysToPascalCaseRecursive({
          orderTypes: orderTypeOptionsWithMapping,
        }),
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductDownloadFile = `${baseUrl}/api/product/airbuspartdocument`;
  public static async handleProductDownloadFileAirbus(settings: AxiosRequestConfig) {
    const params = settings.params;
    const fileName = params.fileName || 'file';
    try {
      const response = await apim.downloadPartDocument(SATAIR_BASESITE_ID, params.id, {
        fileName,
      });
      const processResponse = await toLegacyDownloadResponse(response, fileName);
      return toLegacyResponse(response, {
        Data: processResponse,
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  public static ProductFHSDetails = `${baseUrl}/api/product/fhsdetails`;
  public static async handleProductFHSDetails(settings: AxiosRequestConfig) {
    try {
      const response = await apim.getFhsDetails(SATAIR_BASESITE_ID, settings.data);
      return toLegacyResponse(response, {
        Data: response.data,
      });
    } catch (error) {
      const handleError = await handleErrorResponse(error);
      throw toLegacyResponse(error, handleError);
    }
  }
  // ! REQUIRES NEXT UMBRACO IMPLEMENTATION [Route("product/servicereference", Name = AttributeRouteNames.GetServiceReference)]
  public static ServiceReference = () => `${baseUrl}/api/product/servicereference`;
  public static ProductDetails = (manufacturer: string, manufacturerAid: string) =>
    `${baseUrl}/market/product/${manufacturer}/${manufacturerAid}`;
  public static OfferDetails = (manufacturer: string, manufacturerAid: string, offerId: string) =>
    `${baseUrl}/market/product/${manufacturer}/${manufacturerAid}/${offerId}`;
  public static ProductInterchangeableIndicator = (pno: string, manu: string) =>
    `${baseUrl}/api/product/interchangeable/indicator/${pno}/${manu}`;
  public static ProductAvailability = (code: string, quantity: number = 1) =>
    `${baseUrl}/api/product/availability/${code}/${quantity}`;
  public static ProductAvailabilityBundle: string = `${baseUrl}/api/product/availability/`;
  public static ProductRecommendations = () => `${baseUrl}/api/product/recommendations`;
}
