import { HybrisProductVolumePrice } from './HybrisProductVolumePrice';
import { HybrisQuoteResponseEntry } from './HybrisQuoteResponseEntry';

export interface IHybrisQuoteResponse {
  entries: HybrisQuoteResponseEntry[];
  expirationDate: string;
  miraklQuoteResponseId: string;
  price: HybrisProductVolumePrice;
  shippingPrice: HybrisProductVolumePrice;
  state: string;
  totalPrice: HybrisProductVolumePrice;
}

export class HybrisQuoteResponse implements IHybrisQuoteResponse {
  entries: HybrisQuoteResponseEntry[];
  expirationDate: string;
  miraklQuoteResponseId: string;
  price: HybrisProductVolumePrice;
  shippingPrice: HybrisProductVolumePrice;
  state: string;
  totalPrice: HybrisProductVolumePrice;

  constructor(init?: Partial<HybrisQuoteResponse>) {
    Object.assign(this, init);
  }
}
