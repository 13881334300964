import { HybrisQuoteMessage } from './HybrisQuoteMessage';
import { HybrisQuoteResponse } from './HybrisQuoteResponse';

export interface IHybrisOfferQuoteResponses {
  messages: HybrisQuoteMessage[];
  quoteResponses: HybrisQuoteResponse[];
  subject: string;
  masterShopName: string;
  locationDisplayName: string;
}

export class HybrisOfferQuoteResponses implements IHybrisOfferQuoteResponses {
  messages: HybrisQuoteMessage[];
  quoteResponses: HybrisQuoteResponse[];
  subject: string;
  masterShopName: string;
  locationDisplayName: string;

  constructor(init?: Partial<HybrisOfferQuoteResponses>) {
    Object.assign(this, init);
  }
}
