import { QuoteApi } from '@/src/core/api';
import DownloadFile from '@/src/core/services/download';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { CancelReqCategory, Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import {
  Carrier,
  CartEntry,
  Facets,
  OwningSystem,
  ProductPrice,
  Quote,
  QuoteDetail,
  QuoteEntryRequest,
  SellerGroup,
} from '@/src/core/types/api';
import {
  IQuoteEntryRequest,
  IQuoteListQuery,
  IQuoteOverview,
  ISellerGroup,
} from '@/src/core/types/interfaces';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { isQuoteReady, shouldHidePricesForQuote } from '@/src/market/utils/quote';
import { useUserStore } from '@/src/profile/stores/user';
import { defineStore } from 'pinia';
import { useCheckoutStore } from './checkout';

interface QuotesState {
  quotes: Quote[];
  page: number;
  totalPages: number;
  totalCount: number;
  currentQuoteView: QuoteDetail;
  checkoutQuote: QuoteDetail;
  filters: IQuoteListQuery;
  selectedQuoteEntry: CartEntry;
  quoteResponseId: string;
  selectedOrderLines: QuoteEntryRequest[];
  isCalculating: boolean;
  isBusyDetails: boolean;
  isBusyList: boolean;
  isInvalid: boolean;
  facets: Facets[];
}

export const useQuotesStore = defineStore('quotes', {
  state: (): QuotesState => ({
    quotes: [],
    page: 0,
    totalPages: 0,
    totalCount: 0,
    currentQuoteView: {} as QuoteDetail,
    checkoutQuote: {} as QuoteDetail,
    filters: {} as IQuoteListQuery,
    selectedQuoteEntry: {} as CartEntry,
    selectedOrderLines: [],
    quoteResponseId: '',
    facets: [],
    isCalculating: false,
    isBusyDetails: false,
    isBusyList: false,
    isInvalid: false,
  }),
  getters: {
    quoteSellerGroup(state) {
      // Fake a sellerGroup (used to checkout)
      return () => {
        const sellerGroup = {
          Key: 'satair',
          Name: 'satair',
          Entries: state.checkoutQuote.Entries,
          SubTotal: state.checkoutQuote.TotalPrice,
          TotalPrice: state.checkoutQuote.TotalPrice,
          Price: state.checkoutQuote.TotalPrice,
        };
        return state.checkoutQuote.Entries?.length > 0 ? sellerGroup : ({} as ISellerGroup);
      };
    },
    sellers(state) {
      // Fake a sellerGroup (used to checkout)
      const sellerGroup = {
        Key: 'satair',
        Name: 'satair',
        Entries: state.checkoutQuote.Entries,
        SubTotal: state.checkoutQuote.TotalPrice,
        TotalPrice: state.checkoutQuote.TotalPrice,
        Price: state.checkoutQuote.TotalPrice,
      } as unknown as SellerGroup;
      return [sellerGroup];
    },
    satairQuotesEnabled() {
      const userStore = useUserStore();
      return userStore.redCustomer && hasFeature(FEATURES.SATAIR_QUOTES);
    },
    airbusQuotesEnabled() {
      const userStore = useUserStore();
      return userStore.blueCustomer && hasFeature(FEATURES.BLUE_LIGHT_QUOTE);
    },
    propietaryPartsServiceQuotesEnabled() {
      const userStore = useUserStore();
      return (
        userStore.blueCustomer &&
        hasFeature(FEATURES.BLUE_LIGHT_QUOTE) &&
        hasFeature(FEATURES.AIRBUS_PROP_PARTS_SERVICE_QUOTATION)
      );
    },
    toolServiceQuotesEnabled() {
      const userStore = useUserStore();
      return (
        userStore.blueCustomer &&
        hasFeature(FEATURES.BLUE_LIGHT_QUOTE) &&
        hasFeature(FEATURES.AIRBUS_TOOL_SERVICE_QUOTATION)
      );
    },
    showQuotes() {
      const userStore = useUserStore();
      return (userStore.blueCustomer && hasFeature(FEATURES.BLUE_QUOTES)) || userStore.redCustomer;
    },
  },
  actions: {
    orderListFiltersUpdated(payload: { Query: IQuoteListQuery }) {
      this.filters = Object.assign({}, this.filters, payload.Query);
    },
    checkoutQuoteUpdated(payload: {
      quoteDetail: QuoteDetail;
      quoteTotalRequests: QuoteEntryRequest[];
    }) {
      const newCheckoutEntries: CartEntry[] = [];
      if (payload.quoteTotalRequests.length > 0) {
        payload.quoteDetail.Entries.forEach((requestEntry) => {
          const entryIndex = payload.quoteTotalRequests.findIndex(
            (productEntry) => productEntry.EntryNumber === requestEntry.EntryNumber,
          );

          newCheckoutEntries.push(Object.assign({}, payload.quoteDetail.Entries[entryIndex]));
        });

        this.checkoutQuote = Object.assign({}, this.currentQuoteView, payload.quoteDetail, {
          Entries: newCheckoutEntries,
        });
      }
    },
    airbusCheckoutQuoteUpdated(payload: {
      quoteDetail: QuoteDetail;
      quoteTotalRequests: QuoteEntryRequest[];
    }) {
      // find the product in the checkoutQuote that matches the product in the response and update the price
      payload.quoteDetail.Entries.forEach((requestEntry) => {
        const entryIndex = this.checkoutQuote.Entries.findIndex(
          (productEntry) => productEntry.EntryNumber === requestEntry.EntryNumber,
        );

        if (entryIndex > -1) {
          this.checkoutQuote.Entries[entryIndex].TotalPrice = payload.quoteDetail.TotalPrice;
          this.checkoutQuote.Entries[entryIndex].BasePrice =
            payload.quoteDetail.Entries[0].UnitPrice;
        }
      });

      // update the total price of the quote
      this.checkoutQuote.TotalPrice = payload.quoteDetail.TotalPrice;
    },
    quoteListUpdated(payload: { QuoteList: IQuoteOverview; loadMore?: boolean }) {
      if (!payload.loadMore) {
        this.quotes = [];
      }
      this.quotes.push(...payload.QuoteList.Quotes);
      this.page = payload.QuoteList.Page;
      this.totalPages = payload.QuoteList.TotalPages;
      this.facets.push(...payload.QuoteList.Facets);

      if (this.totalCount === 0) {
        this.totalCount = payload.QuoteList.Count;
      }
    },
    currentQuoteViewUpdated(payload: { quoteDetail: QuoteDetail }) {
      const MasterShopName = payload.quoteDetail.MasterShopName;
      const LocationDisplayName = payload.quoteDetail.LocationDisplayName;
      const quoteDetails = {
        ...this.currentQuoteView,
        ...payload.quoteDetail,
        LocationDisplayName,
        MasterShopName,
      };
      const shouldHidePricing = shouldHidePricesForQuote(quoteDetails);

      if (shouldHidePricing) {
        quoteDetails.Entries.forEach((entry) => {
          delete entry.BasePrice;
        });

        quoteDetails.TotalPrice = {
          Value: '0',
        } as ProductPrice;
      }

      this.checkoutQuote = quoteDetails;
      this.currentQuoteView = quoteDetails;

      payload.quoteDetail.Entries.forEach((entry) => {
        if (
          entry.RemainingQuantity &&
          entry.RemainingQuantity > 0 &&
          !entry.InitialQuoteRequest &&
          entry.BasePrice?.Value !== '0' &&
          !entry.EntryIsCancelled &&
          entry.Orderable
        ) {
          this.selectedOrderLines.push({
            EntryNumber: entry.EntryNumber,
            Quantity: entry.RemainingQuantity || 0,
          });
        }
      });
    },
    async cancelFetchQuoteList() {
      CancelReqCategory('QuoteList');
    },
    setCheckoutQuoteMessages(payload: { Messages: QuoteDetail['Messages'] }) {
      this.checkoutQuote = Object.assign({}, this.checkoutQuote, payload);
    },
    resetQuoteDetails() {
      this.currentQuoteView = {} as QuoteDetail;
      this.selectedOrderLines = [];
      this.isInvalid = false;
    },
    resetCheckoutQuote() {
      this.checkoutQuote = {} as QuoteDetail;
      this.selectedQuoteEntry = {} as CartEntry;
      this.selectedOrderLines = [];
      this.isInvalid = false;
    },
    clearFilters() {
      this.filters = {};
    },
    async fetchQuotesList() {
      this.isBusyList = true;

      const { IsSuccess, Data } = await Req(
        {
          url: QuoteApi.AllQuotes,
          params: {
            q: this.filters.q || '',
            qFacet: this.filters.qfacet || '',
            status: this.filters.status || '',
            origin: this.filters.originDisplay || '',
          },
        },
        new ReqQueue(ReqQueueTypes.Default, 'QuoteList'),
      );

      if (IsSuccess) {
        this.quoteListUpdated({ QuoteList: Data });
      }

      this.isBusyList = false;

      return { IsSuccess };
    },
    async loadMoreQuotesList() {
      const pageNumber = this.page + 1;

      if (pageNumber === this.totalPages) {
        return;
      }

      this.isBusyList = true;

      const { IsSuccess, Data } = await Req({
        url: QuoteApi.AllQuotes,
        params: {
          q: this.filters.q,
          p: pageNumber,
          qFacet: this.filters.qfacet || '',
          status: this.filters.status || '',
          origin: this.filters.originDisplay || '',
        },
      });

      if (IsSuccess) {
        this.quoteListUpdated({ QuoteList: Data, loadMore: true });
      }

      this.isBusyList = false;

      return { IsSuccess };
    },
    async fetchQuoteDetails(payload: { quoteCode: string }) {
      this.isBusyDetails = true;

      const { IsSuccess, Data } = await Req({
        url: QuoteApi.QuoteInfo(payload.quoteCode),
      });

      if (IsSuccess && Data) {
        this.currentQuoteViewUpdated({ quoteDetail: Data });

        if (isQuoteReady(Data.State) && this.selectedOrderLines.length > 0) {
          await this.calculateQuoteTotal({ quoteCode: payload.quoteCode });
        }
      }

      this.isBusyDetails = false;

      return { IsSuccess };
    },
    async downloadDocument(payload: { quoteCode: string; sapQuoteCode: string }) {
      const { IsSuccess, Data } = await DownloadFile({
        method: 'POST',
        url: QuoteApi.DownloadQuoteDocument(payload.quoteCode),
        data: { sapQuoteId: payload.sapQuoteCode },
      });

      return { IsSuccess, Data };
    },
    async calculateQuoteTotal(payload: { quoteCode: string }) {
      this.isCalculating = true;

      if (this.currentQuoteView.OwningSystem === OwningSystem.BLUE) {
        const { IsSuccess, Data } = await Req({
          method: 'POST',
          url: QuoteApi.CalculateAirbusQuoteTotal,
          data: {
            quantity: this.selectedOrderLines[0].Quantity,
            entryNumber: this.selectedOrderLines[0].EntryNumber,
            quoteCode: this.currentQuoteView.Code,
          },
        });

        const mappedData = objectKeysToPascalCaseRecursive(Data);

        if (IsSuccess) {
          this.airbusCheckoutQuoteUpdated({
            quoteDetail: mappedData,
            quoteTotalRequests: this.selectedOrderLines,
          });
        }

        this.isInvalid = false;
        this.isCalculating = false;

        return { IsSuccess, Data };
      } else {
        const { IsSuccess, Data } = await Req({
          method: 'POST',
          url: QuoteApi.CalculateQuoteTotal(payload.quoteCode),
          data: { quoteTotalRequests: this.selectedOrderLines },
        });

        if (IsSuccess) {
          this.checkoutQuoteUpdated({
            quoteDetail: Data,
            quoteTotalRequests: this.selectedOrderLines,
          });
        }

        this.isInvalid = false;
        this.isCalculating = false;

        return { IsSuccess, Data };
      }
    },
    async offerQuoteDetailsUpdated(payload: {
      QuoteResponseId: string;
      SelectedQuoteEntry?: CartEntry;
      QuoteDetail?: QuoteDetail;
    }) {
      this.quoteResponseId = payload.QuoteResponseId;

      if (payload.SelectedQuoteEntry) {
        this.selectedQuoteEntry = payload.SelectedQuoteEntry;

        if (payload.QuoteDetail) {
          payload.QuoteDetail.TotalPriceWithShipping =
            payload.SelectedQuoteEntry.TotalPriceWithShipping;

          this.checkoutQuoteUpdated({
            quoteDetail: payload.QuoteDetail,
            quoteTotalRequests: [],
          });
        }
      }
    },
    async createCheckoutSellers(payload?: { seller: ISellerGroup; cartEntry?: CartEntry[] }) {
      const checkoutStore = useCheckoutStore();

      if (payload) {
        await checkoutStore.addSellerGroup({
          seller: {
            Key: payload.seller.Key,
            Name: payload.seller.Name,
            DeliveryAddress: this.checkoutQuote.DeliveryAddress,
            Carrier: {} as Carrier,
            CarrierInfo: '',
            CarrierInstructions: '',
            Entries: payload.cartEntry ? payload.cartEntry : payload.seller.Entries,
            Url: payload.seller.Url,
            Logo: payload.seller.Logo,
          } as ISellerGroup,
        });
      }
    },
    async extractAndUpdateCheckoutData(payload: { quote: QuoteDetail }) {
      const checkoutStore = useCheckoutStore();

      await checkoutStore.addSellerGroup({
        seller: {
          Key: 'satair',
          Name: 'Satair',
          DeliveryAddress: payload.quote.DeliveryAddress,
          Carrier: {} as Carrier,
          CarrierInfo: '',
          CarrierInstructions: '',
        } as ISellerGroup,
      });

      this.currentQuoteViewUpdated({ quoteDetail: payload.quote });
    },
    async selectOrderLineChange(payload: { entryNumber: number; quantity: number }) {
      const entryIndex = this.selectedOrderLines.findIndex(
        (entry) => entry.EntryNumber === payload.entryNumber,
      );
      if (entryIndex > -1 && payload.quantity > 0) {
        this.selectedOrderLines.splice(entryIndex, 1);
        this.selectedOrderLines.push({
          EntryNumber: payload.entryNumber,
          Quantity: payload.quantity,
        });
        this.calculateQuoteTotal({ quoteCode: this.currentQuoteView.Code });
      } else if (payload.quantity > 0) {
        this.selectedOrderLines.push({
          EntryNumber: payload.entryNumber,
          Quantity: payload.quantity,
        });
        this.calculateQuoteTotal({ quoteCode: this.currentQuoteView.Code });
      } else {
        this.selectedOrderLines.splice(entryIndex, 1);
        if (this.selectedOrderLines.length > 0) {
          this.calculateQuoteTotal({ quoteCode: this.currentQuoteView.Code });
        } else {
          this.isInvalid = true;
        }
      }
    },
    updateSelectedOrderLines(payload: IQuoteEntryRequest[]) {
      const newSelectedOrderLines: QuoteEntryRequest[] = [];
      payload.forEach((line) => {
        newSelectedOrderLines.push({ EntryNumber: line.EntryNumber, Quantity: line.Quantity });
      });

      this.selectedOrderLines = newSelectedOrderLines;
    },
  },
});
