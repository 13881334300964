import { MessageThread } from '@/src/core/apim';
import { MarketplaceMessage } from './MarketplaceMessage';
import { Pagination } from './Pagination';

export class MarketplaceMessagingThread implements MessageThread {
  messages: MarketplaceMessage[] = [];
  pagination?: Pagination;
  createdDate?: string = '0001-01-01T00:00:00'; // ISO 8601 string
  updatedDate?: string = '0001-01-01T00:00:00'; // ISO 8601 string
  threadId?: string;
  subject?: string;
  totalMessageCount: number = 0;
  consignmentId?: string;
  masterShopName?: string;
  locationDisplayName?: string;

  constructor(init?: Partial<MarketplaceMessagingThread>) {
    Object.assign(this, init);
  }
}
