import { MarketplaceMessagingThread } from './MarketplaceMessagingThread';

export class MarketplaceMessagingThreads {
  orderCode?: string;
  threads: MarketplaceMessagingThread[];

  constructor(init?: Partial<MarketplaceMessagingThreads>) {
    Object.assign(this, init);
  }
}
