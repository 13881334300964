import { CartModification, ChangeValue, OrderSearchPage } from '@/src/core/apim';
import { Notification } from '@/src/core/services/notification-mapping';
import { Order } from '@/src/core/types/api';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import {
  getCartData,
  getMaxQuantityNotification,
  handleCustomAction,
  orderAddToCardNotification,
} from '@/src/market/services/legacy-mapping-cart-modification';
import { MASTERSHOP_ID } from '@/src/market/settings/mastershop';

/**
 * Maps a SAP CC plants response to an expanded version of the plants.
 *
 * @param {PlantsStock} plants - A SAP CC plants response.
 * @returns {LegacyPlants} - An expanded version of the plant.
 */
export const getOrderSearchPageWithMapping = (orderSearchPage: OrderSearchPage) => {
  const count = orderSearchPage.pagination?.totalResults;
  const page = orderSearchPage.pagination?.currentPage;
  const totalPages = orderSearchPage.pagination?.totalPages;
  const orders = orderSearchPage.orders?.map((order) => ({
    ...order,
    totalPrice: {
      ...order.totalPrice,
      isAsmHidden: false,
    },
    subTotal: {
      ...order.subTotal,
      isAsmHidden: false,
    },
    deliveryCost: {
      ...order.deliveryCost,
      isAsmHidden: false,
    },
    priorityInfo: {
      airframe: order.priorityInfo?.airframe ?? '',
      priority: {
        code: order.priorityInfo?.priority?.code ?? '',
        name: order.priorityInfo?.priority?.name ?? '',
      },
    },
    masterShopId: order.masterShop?.id ?? '',
    isSatairOrder: order.masterShop?.id === MASTERSHOP_ID.SATAIR,
    supplierDisplay: order.masterShop?.name,
  }));

  const toReturn = {
    ...orderSearchPage,
    count,
    page,
    totalPages,
    orders,
  };

  return {
    Data: objectKeysToPascalCaseRecursive(toReturn, [], ['AirbusExtendedOrderDetails']),
  };
};

export const addOrderToCartData = (
  cartModifications?: CartModification[],
  currentOrder?: Order,
) => {
  const notifications: Notification[] = [];
  const cartData = getCartData(cartModifications);

  if (currentOrder?.PurchaseOrderNumber) {
    cartData.modificationName = currentOrder.PurchaseOrderNumber;
  }

  const addToCartNotification = orderAddToCardNotification(cartData);
  const customAction = handleCustomAction(cartData);
  const maxQuantityNotification = getMaxQuantityNotification(
    cartData,
    currentOrder?.Entries.length,
    true,
  );

  if (maxQuantityNotification) {
    notifications.push(maxQuantityNotification);
  }

  if (addToCartNotification) {
    notifications.push(addToCartNotification);
  }

  return {
    Data: { ...objectKeysToPascalCaseRecursive(cartData) },
    Notification: objectKeysToPascalCaseRecursive(notifications) || [],
    ...(customAction && { CustomActions: objectKeysToPascalCaseRecursive([customAction]) }),
  };
};

export type CartModificationResponseData = ReturnType<typeof addOrderToCartData>;

export const mapOrderLogs = (logs?: ChangeValue[]) => {
  if (!logs) {
    return [];
  }
  return logs.map((log) => mapOrderLog(log));
};

export const mapOrderLog = (logs: ChangeValue) => {
  const modifiedItem = extractEntryProps(logs.modifiedItemId, logs.modifiedItemType);
  const attributeName = logs.friendlyName;
  const category = logs.modifiedItemType;
  const description = logs.infoStatus;
  const type = logs.modificationType;
  const modifiedItemId = modifiedItem?.id;
  const entryIndex = modifiedItem?.entryNumber;
  const cageCode = modifiedItem?.cageCode;
  const containerId = modifiedItem?.containerId;
  const partNumber = modifiedItem?.partNumber;

  return {
    ...logs,
    attributeName,
    category,
    description,
    type,
    modifiedItemId,
    entryIndex,
    cageCode,
    containerId,
    partNumber,
  };
};

const extractEntryProps = (modifiedItemId: string, modifiedItemType: string) => {
  let modifiedItem;

  if (!modifiedItemId.includes(':')) {
    return modifiedItem;
  }

  const props = modifiedItemId.split(':');
  const itemType = modifiedItemType.toLowerCase();

  if (itemType === 'container') {
    modifiedItem = extractContainerProps(props);
  } else if (itemType === 'order entry') {
    modifiedItem = extractOrderEntryProps(props);
  }

  return modifiedItem;
};

const extractContainerProps = (props: string[]) => {
  return {
    containerId: props.length > 1 ? props[1] : '',
  };
};

const extractOrderEntryProps = (props: string[]) => {
  return {
    id: props.length > 0 ? props[0] : '',
    entryNumber: props.length > 1 ? props[1] : '',
    partNumber: props.length > 2 ? props[2] : '',
    cageCode: props.length > 3 ? props[3] : '',
  };
};
