<template>
  <ul>
    <li v-ui-test="'password-validation-requirement'" :class="{ validated: validateMinLength }">
      <span></span>
      <div class="valid" v-if="validateMinLength">
        <Checkmark :is-active="validateMinLength"></Checkmark>
      </div>
      Password should be at least six characters long
    </li>
    <li v-ui-test="'password-validation-requirement'" :class="{ validated: validateUppercase }">
      <span></span>
      <div class="valid" v-if="validateUppercase">
        <Checkmark :is-active="validateUppercase"></Checkmark>
      </div>
      Password should contain at least one uppercase character
    </li>
    <li v-ui-test="'password-validation-requirement'" :class="{ validated: validateDigits }">
      <span></span>
      <div class="valid" v-if="validateDigits">
        <Checkmark :is-active="validateDigits"></Checkmark>
      </div>
      Password should contain at least one number
    </li>
    <li
      v-ui-test="'password-validation-requirement'"
      :class="{ validated: repeatedValue.length > 0 && value === repeatedValue }"
    >
      <span></span>
      <div class="valid" v-if="repeatedValue.length > 0 && value === repeatedValue">
        <Checkmark :is-active="repeatedValue.length > 0 && value === repeatedValue"></Checkmark>
      </div>
      Repeated password should be equal to new password
    </li>
  </ul>
</template>

<style lang="scss" src="./password-validation-box.scss" scoped></style>

<script lang="ts" setup>
import Checkmark from '@/src/core/components/ui/ani-icons/checkmark/checkmark.vue';
import { numberRegEx, uppercaseRegEx } from '@/src/core/utils/regex-library';
import { computed } from 'vue';

interface Props {
  value: string;
  repeatedValue: string;
}

const props = defineProps<Props>();

const validateMinLength = computed(() => {
  return props.value.length > 5;
});

const validateUppercase = computed(() => {
  return uppercaseRegEx.test(props.value);
});

const validateDigits = computed(() => {
  return numberRegEx.test(props.value);
});
</script>
