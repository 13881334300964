import { HybrisMarketplaceMessage } from './HybrisMarketplaceMessage';
import { HybrisMessagePagination } from './HybrisMessagePagination';

export class HybrisMarketplaceMessagingThread {
  messages?: HybrisMarketplaceMessage[];
  pagination?: HybrisMessagePagination;
  createdDate?: string; // ISO 8601 string
  updatedDate?: string; // ISO 8601 string
  threadId?: string;
  subject?: string;
  totalMessageCount: number;
  masterShopName?: string;
  locationDisplayName?: string;

  constructor(init?: Partial<HybrisMarketplaceMessagingThread>) {
    Object.assign(this, init);
  }
}
