import { PlantsStock } from '@/src/core/apim';
import { ConvertToPascalCase } from '@/src/core/utils/object';

export const getProductPlantsWithMapping = (productPlants: PlantsStock) => {
  return {
    code: productPlants.entries?.[0]?.id ?? '',
    hasPlants: (productPlants.entries?.[0]?.details?.length ?? 0) > 0,
    plants:
      productPlants.entries?.[0].details?.map((plant) => {
        return {
          inStock: plant.inStock,
          quantity: plant.quantity,
          unit: plant.unit?.code,
          warehouse: plant.warehouse?.name,
        };
      }) ?? [],
  };
};

export type ProductPlantsWithMapping = ReturnType<typeof getProductPlantsWithMapping>;
export type LegacyProductPlantsWithMapping = ConvertToPascalCase<ProductPlantsWithMapping>;
