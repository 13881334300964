import { IModal } from '@/src/core/types/ui';

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useUIStore } from './ui';

export const useModalStore = defineStore('modal', () => {
  const uiStore = useUIStore();
  const modals = ref<IModal[]>([]);

  const isVisible = computed(() => {
    return modals.value.length > 0;
  });

  const activeModal = computed(() => {
    return (modals.value.length > 0 && modals.value[0]) || undefined;
  });

  const params = computed(() => {
    return activeModal.value?.params;
  });

  const isLocked = computed(() => {
    return activeModal.value?.modalLocked;
  });

  const component = computed(() => {
    return activeModal.value?.modalComponent;
  });

  const modalUpdated = (payload: {
    modalComponent: string | null;
    params?: string | object;
    first?: boolean;
    locked?: boolean;
    single?: boolean;
  }) => {
    // close any open dropdowns
    uiStore.setDiscountModaId(null);

    if (
      payload.single &&
      modals.value.find((item) => item.modalComponent === payload.modalComponent)
    ) {
      return;
    }

    if (payload.first) {
      modals.value.unshift({
        modalComponent: payload.modalComponent,
        modalLocked: payload.locked,
        params: payload.params,
      });
    } else {
      modals.value.push({
        modalComponent: payload.modalComponent,
        modalLocked: payload.locked,
        params: payload.params,
      });
    }
  };

  const reset = () => {
    modals.value = [];
  };

  const showModal = (payload: {
    modalComponent: string | null;
    params?: string | object;
    first?: boolean;
    locked?: boolean;
    single?: boolean;
  }) => {
    modalUpdated(payload);
  };

  const closeModal = (payload?: { modalComponent?: string }) => {
    if (payload?.modalComponent) {
      modals.value = modals.value.filter((item) => item.modalComponent !== payload.modalComponent);
    } else {
      modals.value.splice(0, 1);
    }
  };

  const lockCurrentModal = (lock: boolean) => {
    if (modals.value.length > 0) {
      modals.value[0].modalLocked = lock;
    }
  };

  const closeModalButton = () => {
    closeModal();
  };

  return {
    closeModalButton,
    lockCurrentModal,
    closeModal,
    showModal,
    reset,
    isVisible,
    activeModal,
    params,
    isLocked,
    component,
  };
});
