import { GenerateUniqueIdentifier } from '@/src/core/plugins/uuid4';
import { NotificationTypes } from '@/src/core/types/api';
import { INotificationItem } from '@/src/core/types/ui';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export function GetNotificationTimeout(type: INotificationItem['Type']) {
  switch (type) {
    case NotificationTypes.Information:
      return 7000;
    case NotificationTypes.Success:
      return 5000;
    case NotificationTypes.Warning:
      return 10000;
    case NotificationTypes.Error:
    case NotificationTypes.PasswordPolicyViolationError:
      return 9999000;
    default:
      return 7000;
  }
}

export const useNotificationsStore = defineStore('notifications', () => {
  const notifications = ref<INotificationItem[]>([]);

  const notificationAdded = (payload: {
    notificationItem: INotificationItem;
    notificationId?: string;
  }) => {
    notifications.value.push(payload.notificationItem);
  };

  const notificationRemoved = (payload: { notificationItem: INotificationItem }) => {
    notifications.value.splice(notifications.value.indexOf(payload.notificationItem), 1);
  };

  const reset = () => {
    notifications.value = [];
  };

  const addNotification = async (payload: {
    notificationItem: INotificationItem;
    notificationId?: string;
  }) => {
    if (payload.notificationId && notificationExists(payload.notificationId)) {
      return;
    }

    if (!payload.notificationId) {
      payload.notificationItem.Id = new GenerateUniqueIdentifier().uuid4();
    } else {
      payload.notificationItem.Id = payload.notificationId;
    }

    notificationAdded(payload);
  };

  const removeNotification = async (notificationItem: INotificationItem) => {
    notificationRemoved({ notificationItem });
  };

  const removeNotificationById = async (notificationId: string) => {
    const notificationItem = notifications.value.find((item) => item?.Id === notificationId);

    if (notificationItem) {
      notificationRemoved({ notificationItem });
    }
  };

  const notificationExists = (id: string) => {
    return notifications.value.some((x) => x.Id === id);
  };

  return {
    notificationExists,
    reset,
    removeNotificationById,
    removeNotification,
    addNotification,
    notifications,
  };
});
