import { OwningSystem, Product } from '@/src/core/types/api';
import { AirbusPart, IProductAdditionalData } from '@/src/core/types/interfaces';
import { useAirbusPartStore } from '../stores/airbus-part';
import { useProductAdditionalInfoStore } from '../stores/product-additional-info';
import { computed } from 'vue';
import { PartResponse } from '@/src/core/apim';

// TODO: Rename to isRedOwningSystem
export const isSatair = (
  productData?: IProductAdditionalData | PartResponse | Product | null,
): productData is IProductAdditionalData => {
  return (productData as IProductAdditionalData)?.Id !== undefined;
};

export const isAirbus = (
  productData?: IProductAdditionalData | PartResponse | null,
): productData is PartResponse => {
  return (productData as PartResponse)?.productId !== undefined;
};

export const productAdditionalData = (id: string, owningSystem: string) => {
  const productAdditionalInfoStore = useProductAdditionalInfoStore();
  const airbusPartStore = useAirbusPartStore();

  if (!id && !owningSystem) {
    return null;
  }

  if (owningSystem === OwningSystem.BLUE) {
    return airbusPartStore.airbusPartByOfferId(id);
  } else {
    return productAdditionalInfoStore.additionalDataByOfferId(id);
  }
};

export function useProductAdditionalData(id: string, owningSystem: string) {
  const productAdditionalInfoStore = useProductAdditionalInfoStore();
  const airbusPartStore = useAirbusPartStore();

  const additionalData = computed(() => {
    if (!id && !owningSystem) {
      return null;
    }

    if (owningSystem === OwningSystem.BLUE) {
      return airbusPartStore.airbusPartByOfferId(id);
    } else {
      return productAdditionalInfoStore.additionalDataByOfferId(id);
    }
  });

  return additionalData;
}

export const isAirbusProduct = (owningSystem?: string): boolean => {
  return owningSystem === OwningSystem.BLUE;
};
