import { ProductApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { OwningSystem, Product, ProductOffer } from '@/src/core/types/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { LegacyOffer } from '../services/legacy-mapping-offer';
import { LegacyProduct } from '../services/legacy-mapping-product';

export const useProductStore = defineStore('product', () => {
  const products = ref<LegacyProduct[]>([]);
  const productOffers = ref<LegacyOffer[]>([]);
  const isBusy = ref(false);

  const productUpdated = (payload: { product: LegacyProduct }) => {
    const productIndex =
      payload.product && products.value.findIndex((x) => x?.Id === payload.product.Id);

    if (productIndex >= 0) {
      products.value.splice(
        productIndex,
        1,
        Object.assign({}, products.value[productIndex], payload.product),
      );
    } else {
      products.value.push(payload.product);
    }
  };

  const productOfferUpdated = (payload: { offer: LegacyOffer }) => {
    const productOfferIndex =
      payload.offer && productOffers.value.findIndex((x) => x?.Id === payload.offer.Id);

    if (productOfferIndex >= 0) {
      productOffers.value.splice(
        productOfferIndex,
        1,
        Object.assign({}, productOffers.value[productOfferIndex], payload.offer),
      );
    } else {
      productOffers.value.push(payload.offer);
    }
  };

  const reset = () => {
    products.value = [];
    productOffers.value = [];
  };

  const addProduct = (payload: { product: LegacyProduct }) => {
    productUpdated(payload);
  };

  const fetchAndUpdateProductBySku = async (payload: { sku: string; force?: boolean }) => {
    const product = productBySku(payload.sku);

    if (!product || payload.force) {
      const { IsSuccess, Data } = await Req(
        {
          url: ProductApi.ProductSku(),
          params: {
            sku: payload.sku,
          },
        },
        undefined,
        ProductApi.handleProductSku.bind(ProductApi, payload.sku),
      );

      if (IsSuccess && Data) {
        productUpdated({ product: Data });
      }
    }
  };

  const fetchAndUpdateProductByOfferId = async (payload: { id: string; force?: boolean }) => {
    const product = productById(payload.id);

    if (!product || payload.force) {
      const { IsSuccess, Data } = await Req(
        {
          url: ProductApi.ProductOffer(payload.id),
        },
        undefined,
        ProductApi.handleProductOffer.bind(ProductApi, payload.id),
      );

      if (IsSuccess && Data && Data.Product) {
        productOfferUpdated({ offer: Data });
        productUpdated({ product: Data.Product as LegacyProduct });
      }
      return { IsSuccess };
    } else {
      return { IsSuccess: true };
    }
  };

  const lookupProduct = async (payload: {
    cageCode: string;
    productNumber: string;
    owningSystem: OwningSystem;
  }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req(
      {
        url: ProductApi.ProductByRoute(
          payload.cageCode,
          payload.productNumber,
          payload.owningSystem.toLowerCase(),
        ),
      },
      undefined,
      ProductApi.handleProductByRoute.bind(ProductApi, {
        cageCode: payload.cageCode,
        productNumber: payload.productNumber,
        owningSystem: payload.owningSystem.toLowerCase(),
      }),
    );

    if (IsSuccess && Data && Data.Product) {
      productOfferUpdated({ offer: Data });
      productUpdated({ product: Data.Product as LegacyProduct });
    }

    isBusy.value = false;

    return { IsSuccess };
  };

  const productBySku = (sku: string, owningSystem?: string) => {
    if (owningSystem) {
      return products.value.find((x) => x?.Sku === sku && x.OwningSystem === owningSystem);
    }
    return products.value.find((x) => x?.Sku === sku);
  };

  const productByCode = (code: string) => {
    return products.value.find((x) => x?.Code === code);
  };

  const productById = (id: string) => {
    return products.value.find((x) => x?.Id === id);
  };

  const skuByCode = (id: string) => {
    return productById(id)?.Sku || '';
  };

  const codeBySku = (sku: string) => {
    return productByCode(sku)?.Code || '';
  };

  const productBySkuAndOwningSystem = (sku: string, owningSystem: OwningSystem) => {
    return products.value.find((x) => x?.Sku === sku && x?.OwningSystem === owningSystem);
  };

  const productOfferById = (id: string) => {
    return productOffers.value.find((x) => x?.Id === id);
  };

  return {
    isBusy,
    products,
    productOffers,
    reset,
    addProduct,
    fetchAndUpdateProductByOfferId,
    fetchAndUpdateProductBySku,
    lookupProduct,
    productBySku,
    productByCode,
    productById,
    skuByCode,
    codeBySku,
    productBySkuAndOwningSystem,
    productOfferById,
  };
});
