import { computed, Ref, ref, watch } from 'vue';
import { PasswordPolicySchema, PasswordViolationErrors } from '../services/password-policy-schema';
import { debounce } from 'lodash';
import { flatten, parse } from 'valibot';
import useText from './useText';

export function useHardenedPasswordPolicy(
  payload: Ref<{
    oldPassword: string;
    newPassword: string;
    email: string;
  }>,
) {
  const errors = ref<Partial<Record<any, [string, ...string[]]>> | null>(null);
  const isValid = ref(false);
  const textK = useText();

  const formValuesComputed = computed(() => payload.value);

  const validatePasswordErrors = computed(() => {
    return errors.value?.newPassword;
  });

  const validateMinLength = computed(() => {
    if (formValuesComputed.value.newPassword) {
      return !validatePasswordErrors.value?.includes(PasswordViolationErrors.MIN_LENGTH);
    }
    return false;
  });

  const validateMaxLength = computed(() => {
    if (formValuesComputed.value.newPassword) {
      return !validatePasswordErrors.value?.includes(PasswordViolationErrors.MAX_LENGTH);
    }
    return false;
  });

  const validateAirbusSatair = computed(() => {
    if (formValuesComputed.value.newPassword) {
      return !validatePasswordErrors.value?.includes(
        PasswordViolationErrors.CONTAINS_AIRBUS_SATAIR,
      );
    }
    return false;
  });

  const validateEmail = computed(() => {
    if (formValuesComputed.value.newPassword) {
      return !validatePasswordErrors.value?.includes(PasswordViolationErrors.PASSWORD_EMAIL_SAME);
    }
    return false;
  });

  const validatePreviousPassword = computed(() => {
    if (formValuesComputed.value.newPassword) {
      return !validatePasswordErrors.value?.includes(PasswordViolationErrors.OLD_NEW_PASSWORD_SAME);
    }
    return false;
  });

  const validate = debounce(() => {
    try {
      parse(PasswordPolicySchema, formValuesComputed.value);
      errors.value = null;
      isValid.value = true;
    } catch (error) {
      const valibotErrors = flatten(error).nested;
      errors.value = valibotErrors;
      isValid.value = false;
    }
  }, 600);

  watch(formValuesComputed, validate, { deep: true });

  const errorMessage = computed(() => {
    if (!validateMinLength.value) {
      return textK('UI_PASSWORD_POLICY_MIN_LENGTH');
    }

    if (!validateMaxLength.value) {
      return textK('UI_PASSWORD_POLICY_MAX_LENGTH');
    }

    if (!validateAirbusSatair.value) {
      return textK('UI_PASSWORD_POLICY_NO_AIRBUS_SATAIR');
    }

    if (!validatePreviousPassword.value) {
      return textK('UI_PASSWORD_POLICY_PREVIOUS_PASSWORD');
    }

    if (!validateEmail.value) {
      return textK('UI_PASSWORD_POLICY_EMAIL');
    }
    return '';
  });

  return {
    isValid,
    errors,
    errorMessage,
    validateMinLength,
    validateMaxLength,
    validateAirbusSatair,
    validateEmail,
    validatePreviousPassword,
  };
}
