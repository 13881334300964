/**
 * Options for serialization
 */
export interface SerializationOptions {
  stripUndefined?: boolean; // If true, exclude undefined properties
  removeProperties?: string[]; // Properties to remove
  removePropertiesIfEmpty?: string[]; // Properties to remove if they are empty (e.g., empty arrays or objects)
  removePropertiesIfValue?: { [key: string]: any }; // Remove properties if they match specific values
  customCondition?: (key: string, value: any) => boolean; // Custom function to determine if a property should be removed
}

/**
 * Recursively serializes an object or array, converting class instances to plain objects.
 * @param input The object or array to serialize.
 * @param options Serialization options.
 * @returns The serialized plain object.
 */
export function serializeObject(input: any, options: SerializationOptions = {}): any {
  if (input === null || input === undefined) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map((item) => serializeObject(item, options));
    // } else if (input instanceof Date) {
    //   return input.toISOString();
  } else if (typeof input === 'object') {
    // Create a plain object to hold the serialized data
    const output: any = {};

    // Get all own properties (including non-enumerable ones)
    const propertyNames = Object.getOwnPropertyNames(input);

    for (const key of propertyNames) {
      const value = input[key];

      // Skip functions
      if (typeof value === 'function') {
        continue;
      }

      // Check if the property should be removed based on name
      if (options.removeProperties?.includes(key)) {
        continue;
      }

      // Check if the property should be removed based on value
      if (
        options.removePropertiesIfValue &&
        key in options.removePropertiesIfValue &&
        options.removePropertiesIfValue[key] === value
      ) {
        continue;
      }

      // Check custom condition
      if (options.customCondition && options.customCondition(key, value)) {
        continue;
      }

      // Process the value
      const processedValue = serializeObject(value, options);

      // Strip undefined values if option is set
      if (options.stripUndefined && processedValue === undefined) {
        continue;
      }

      // Remove properties if they are empty arrays or objects
      if (
        options.removePropertiesIfEmpty?.includes(key) &&
        ((Array.isArray(processedValue) && processedValue.length === 0) ||
          (typeof processedValue === 'object' &&
            processedValue !== null &&
            Object.keys(processedValue).length === 0) ||
          (typeof processedValue === 'string' && processedValue === ''))
      ) {
        continue;
      }

      output[key] = processedValue;
    }

    return output;
  } else {
    // Primitive value (string, number, boolean, etc.)
    return input;
  }
}
