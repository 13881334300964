export const parseDateToObject = (dateStr?: string): Date | null => {
  if (!dateStr) {
    return null;
  }

  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day); // Returning a Date object
};

export function dateStringToIsoWithoutMS(dateString?: string): string | undefined {
  if (!dateString) return dateString;
  return new Date(dateString).toISOString().split('.')[0] + 'Z';
}
