import { QuoteResponse, QuoteResponseEntry } from '@/src/core/apim/spec';
import { HybrisProductVolumePrice } from './HybrisProductVolumePrice';
import { HybrisUnit } from './HybrisUnit';

export interface IHybrisQuoteResponseEntry {
  miraklQuoteResponseLineId: string;
  price: HybrisProductVolumePrice;
  quantity: number;
  totalPrice: HybrisProductVolumePrice;
  priceAdditionalInfo: string;
  priceUnit: HybrisUnit;
}

export class HybrisQuoteResponseEntry implements IHybrisQuoteResponseEntry {
  miraklQuoteResponseLineId: string;
  price: HybrisProductVolumePrice;
  quantity: number;
  totalPrice: HybrisProductVolumePrice;
  priceAdditionalInfo: string;
  priceUnit: HybrisUnit;

  constructor(init?: Partial<HybrisQuoteResponseEntry>) {
    Object.assign(this, init);
  }
}
