// tslint:disable
import { getEnv } from '@/src/core/services/environment';
import { toLegacyResponse } from '@/src/core/services/legacy-response-utils';
import { legacyErrorHandler } from '../services/error-notification-handling';
import { apim, ErrorList, HttpResponse, MessageRequest, MessageThread } from '@/src/core/apim';
import { SATAIR_BASESITE_ID } from '../settings/baesiteId';
import Cookies from 'js-cookie';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';
import { serializeObject } from '../services/mapping-service';
import {
  mapHybrisQuoteMessageToQuoteMessage,
  mapQuoteResponsesToHybrisOfferQuoteResponses,
} from '../mappers/quote-mappings';
import { ProductApi } from './productApi';
import { toLegacyDownloadResponse } from '@/src/core/services/legacy-download-response';
import { readFileAsBase64 } from '../services/file-service';
import { MarketplaceMessagingThread } from '../types/core/MarketplaceMessagingThread';
import {
  mapHybrisMarketplaceMessagingThreadToMarketplaceMessagingThread,
  mapMessageThreadToHybrisMarketplaceMessagingThread,
} from '../mappers/offer-mappings';
import {
  mapHybrisMarketplaceMessagingThreadsToMarketplaceMessagingThreads,
  mapOrderThreadsToHybrisMarketplaceMessagingThreads,
} from '../mappers/order-mappings';
import { parseDateToObject } from '../services/date-service';
import { CoreQuoteMessage } from '../types/core/CoreQuoteMessage';

const baseUrl = getEnv('VUE_APP_HOST_UMBRACO') ?? '';

export class MessagingApi {
  public static GetMessages = (offerId: string) => `${baseUrl}/api/messages/offers/${offerId}`;
  public static GetOrderMessages = (orderCode: string, consignmentCode: string) =>
    `${baseUrl}/api/messages/orders/${orderCode}/${consignmentCode}`;
  public static GetQuoteMessages = (quoteCode: string) =>
    `${baseUrl}/api/messages/quotes/${quoteCode}`;
  public static PostMessage = (offerId: string) => `${baseUrl}/api/messages/offers/${offerId}/post`;
  public static PostOrderMessage = (orderCode: string, consignmentCode: string) =>
    `${baseUrl}/api/messages/orders/${orderCode}/${consignmentCode}/post`;
  public static PostQuoteMessage = (quoteCode: string, miraklQuoteRequestId: string) =>
    `${baseUrl}/api/messages/quotes/${quoteCode}/${miraklQuoteRequestId}/post`;

  //Download
  public static DownloadOfferMessageDocument = (offerId: string) =>
    `${baseUrl}/api/messages/offers/${offerId}/download`;
  public static DownloadOrderMessageDocument = (
    orderCode: string,
    threadId: string,
    consignmentCode: string,
  ) => `${baseUrl}/api/messages/orders/${orderCode}/${consignmentCode}/${threadId}/download`;
  public static DownloadQuoteMessageDocument = (
    quoteCode: string,
    miraklQuoteRequestId: string,
    messageId: string,
  ) => `${baseUrl}/api/messages/quotes/${quoteCode}/${miraklQuoteRequestId}/${messageId}/download`;

  // 1. Handle messages/offers/{offerId}
  public static async handleGetOfferMessages({ offerId, params = {} }: any) {
    const userId = Cookies.get('globalId')!;

    try {
      let threadMessages: MessageThread = {};
      let threadMessagesCall: HttpResponse<MessageThread, ErrorList>;
      let offerCall: HttpResponse<any, ErrorList>;

      try {
        //! This will fail at certain times when there are no prior messages resulting in a 400 bad request from the SAP-CC backend
        // This results in a "throw" from the request module
        threadMessagesCall = await apim.getSpecificThreadForUserAndOffer(
          SATAIR_BASESITE_ID,
          offerId,
          userId,
          {
            fields: 'FULL',
          },
        );

        threadMessages = threadMessagesCall.data;
      } catch (error) {}

      const hybrisThreadMessages =
        mapMessageThreadToHybrisMarketplaceMessagingThread(threadMessages);
      let marketplaceThreadMessages =
        mapHybrisMarketplaceMessagingThreadToMarketplaceMessagingThread(hybrisThreadMessages);

      if (marketplaceThreadMessages.messages.length != 0) {
        marketplaceThreadMessages.messages.sort((a, b) => {
          const dateA = parseDateToObject(a.createdDate);
          const dateB = parseDateToObject(b.createdDate);

          if (!dateA) return 1;
          if (!dateB) return -1;

          return dateB.getTime() - dateA.getTime();
        });
      } else {
        marketplaceThreadMessages = new MarketplaceMessagingThread();
        // const noDataTexts = Nodate
        offerCall = await ProductApi.handleProductOffer(offerId);
        const offer = offerCall.data.Data;

        marketplaceThreadMessages.locationDisplayName = offer?.Shop.LocationDisplayName;
        marketplaceThreadMessages.masterShopName = offer?.MasterShopName;
      }

      marketplaceThreadMessages.messages.forEach(
        (x) =>
          (x.authorName =
            x.authorType?.toLowerCase() == 'operator_user' ||
            x.authorType?.toLowerCase() == 'shop_user'
              ? marketplaceThreadMessages.masterShopName +
                ', ' +
                marketplaceThreadMessages.locationDisplayName
              : x.authorName),
      );

      return toLegacyResponse(threadMessagesCall! || offerCall!, {
        Data: objectKeysToPascalCaseRecursive(
          serializeObject(marketplaceThreadMessages, {
            stripUndefined: true,
            customCondition(key, value) {
              if (key == 'pagination' && value) return true;
              return false;
            },
          }),
        ),
      });
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }

  /**
   * 2. Handle messages/offers/{offerId}/post in MessagingController
   */
  public static async handlePostOfferMessage({ offerId, data }: any) {
    const userId = Cookies.get('globalId')!;

    let attachments = [];
    const entries: any[][] = Array.from(data.entries());

    // Assuming the first entry is your file input
    const fileFieldNames = entries.filter(([_, value]) => value instanceof File);

    for (const [name, file] of fileFieldNames) {
      const base64 = await readFileAsBase64(file);

      attachments.push({
        name: name,
        content: base64,
      });
    }

    try {
      // Now, construct the messageRequest after the file has been read
      const messageRequest: MessageRequest = {
        subject: data.get('Subject'),
        body: data.get('Body'),
        attachments: attachments,
      };

      let response;

      try {
        response = await apim.replyToThread(SATAIR_BASESITE_ID, offerId, userId, messageRequest);
      } catch (error) {}

      if (!response) {
        response = await apim.createThreadForUserAndOffer(SATAIR_BASESITE_ID, offerId, userId, {
          body: messageRequest.body,
          subject: messageRequest.subject,
        });

        if (messageRequest.attachments?.length !== 0) {
          messageRequest.body = 'Attachment(s):';

          response = await apim.createThreadForUserAndOffer(
            SATAIR_BASESITE_ID,
            offerId,
            userId,
            messageRequest,
          );

          return toLegacyResponse(response, {});
        }
      }

      return toLegacyResponse(response, {});
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }

  /**
   * 3. Handle messages/offers/{offerId}/download
   */
  public static async handleDownloadOfferMessageDocument({ offerId, attachment }: any) {
    const userId = Cookies.get('globalId')!;

    const orderDocuments = await apim.getAttachedDocumentForUserAndOffer(
      SATAIR_BASESITE_ID,
      attachment.Id,
      offerId,
      userId,
    );

    const processedResponse = await toLegacyDownloadResponse(orderDocuments, attachment.Name);

    try {
      return toLegacyResponse(orderDocuments, {
        Data: processedResponse,
      });
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }

  /**
   * (4) Orders: Handle messages/orders/{orderCode}/{consignmentCode}
   */
  public static async handleGetOrderMessages({ consignmentCode, orderCode }: any) {
    const userId = Cookies.get('globalId')!;

    try {
      const orderThreads = await apim.getOrderThreads(
        SATAIR_BASESITE_ID,
        consignmentCode,
        orderCode,
        userId,
      );

      const messagingThreads = mapOrderThreadsToHybrisMarketplaceMessagingThreads(
        orderThreads.data,
      );
      const marketplaceData =
        mapHybrisMarketplaceMessagingThreadsToMarketplaceMessagingThreads(messagingThreads);

      // const thread = new MarketplaceMessagingThread();
      let thread = new MarketplaceMessagingThread();

      if (marketplaceData.threads.length !== 0) {
        thread = marketplaceData.threads[0];
      }

      const threadMessages = await apim.getSpecificThreadForUserAndOrder(
        SATAIR_BASESITE_ID,
        consignmentCode,
        orderCode,
        thread.threadId ?? '',
        userId,
      );

      const hybrisThreadMessages = mapMessageThreadToHybrisMarketplaceMessagingThread(
        threadMessages.data,
      );

      const marketplaceThreadMessages =
        mapHybrisMarketplaceMessagingThreadToMarketplaceMessagingThread(hybrisThreadMessages);

      marketplaceThreadMessages.messages?.sort((a, b) => {
        const dateA = parseDateToObject(a.createdDate);
        const dateB = parseDateToObject(b.createdDate);

        if (!dateA) return 1;
        if (!dateB) return -1;

        return dateB.getTime() - dateA.getTime();
      });

      marketplaceThreadMessages.consignmentId = consignmentCode;

      const serializedObject = serializeObject(marketplaceThreadMessages, {
        stripUndefined: true,
        removeProperties: ['pagination'],
        removePropertiesIfEmpty: ['attachmentInfos'],
      });

      return toLegacyResponse(orderThreads, {
        Data: objectKeysToPascalCaseRecursive(serializedObject),
      });
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }

  /**
   * 5. Handle messages/orders/{orderCode}/{consignmentCode}/post in MessagingController
   */
  public static async handlePostOrderMessage({ orderCode, consignmentCode, data }: any) {
    const userId = Cookies.get('globalId')!;

    let attachments = [];
    const entries: any[][] = Array.from(data.entries());

    // Assuming the first entry is your file input
    const fileFieldNames = entries.filter(([_, value]) => value instanceof File);

    for (const [name, file] of fileFieldNames) {
      const base64 = await readFileAsBase64(file);

      attachments.push({
        name: name,
        content: base64,
      });
    }

    let threadResult;

    try {
      // Now, construct the messageRequest after the file has been read
      const messageRequest: MessageRequest = {
        subject: data.get('Subject'),
        body: data.get('Body'),
        attachments: attachments,
      };

      let threadId = data.has('ThreadId') ? data.get('ThreadId').trim() : undefined;

      if (threadId && threadId !== '') {
        // reply to thread
        threadResult = await apim.replyToOrderThread(
          SATAIR_BASESITE_ID,
          consignmentCode,
          orderCode,
          threadId,
          userId,
          messageRequest,
        );

        return toLegacyResponse(threadResult, {});
      } else {
        // First post message
        threadResult = await apim.createThreadForUserAndOrder(
          SATAIR_BASESITE_ID,
          consignmentCode,
          orderCode,
          userId,
          {
            body: messageRequest.body,
            subject: messageRequest.subject,
          },
        );

        if (threadResult.ok && messageRequest.attachments?.length !== 0) {
          // then post attachments
          messageRequest.body = 'Attachment(s):';
          const threadResultAttachments = await apim.replyToOrderThread(
            SATAIR_BASESITE_ID,
            consignmentCode,
            orderCode,
            threadResult.data.threadId!,
            userId,
            messageRequest,
          );

          return toLegacyResponse(threadResultAttachments, {});
        }
      }

      return toLegacyResponse(threadResult, {});
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }

  /**
   * (6) Orders: Handle messages/orders/{orderCode}/{consignmentCode}/{threadId}/download
   */
  public static async handleDownloadOrdersMessageDocument({
    orderCode,
    threadId,
    consignmentCode,
    attachment,
  }: any) {
    const userId = Cookies.get('globalId')!;

    const orderDocuments = await apim.getAttachedDocumentFromOrderChat(
      SATAIR_BASESITE_ID,
      consignmentCode,
      attachment.Id,
      orderCode,
      threadId,
      userId,
    );

    const processedResponse = await toLegacyDownloadResponse(orderDocuments, attachment.Name);

    try {
      return toLegacyResponse(orderDocuments, {
        Data: processedResponse,
      });
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }

  /**
   * 7. Handle messages/quotes/{quoteCode}
   */
  public static async handleGetQuoteMessages({ quoteCode }: any) {
    const userId = Cookies.get('globalId')!;

    try {
      const quoteResponsesHttp = await apim.getMiraklQuoteResponses(
        SATAIR_BASESITE_ID,
        quoteCode,
        userId,
        {
          fields: 'FULL',
        },
      );

      const quoteResponses = quoteResponsesHttp.data;

      const mappedQuoteResponses = mapQuoteResponsesToHybrisOfferQuoteResponses(quoteResponses);

      if (mappedQuoteResponses.messages.length > 0) {
        const messages: CoreQuoteMessage[] = mappedQuoteResponses.messages.map((m) =>
          mapHybrisQuoteMessageToQuoteMessage(m),
        );
        messages.forEach((item) => {
          item.LocationDisplayName = quoteResponses.locationDisplayName ?? '';
          item.MasterShopName = quoteResponses.masterShopName ?? '';
        });

        return toLegacyResponse(quoteResponsesHttp, {
          Data: objectKeysToPascalCaseRecursive(
            serializeObject(messages, {
              removePropertiesIfEmpty: ['Id', 'LocationDisplayName', 'MasterShopName'],
              stripUndefined: true,
            }),
          ),
        });
      }

      return toLegacyResponse(quoteResponsesHttp, {
        Data: null,
      });
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }

  /**
   * (8) Quotes: Handle messages/quotes/{quoteCode}/{miraklQuoteRequestId}/post
   */
  public static async handlePostQuoteMessage({ quoteCode, miraklQuoteRequestId, data }: any) {
    const userId = Cookies.get('globalId')!;

    let attachments = [];
    const entries: any[][] = Array.from(data.entries());

    // Assuming the first entry is your file input
    const fileFieldNames = entries.filter(([_, value]) => value instanceof File);

    for (const [name, file] of fileFieldNames) {
      const base64 = await readFileAsBase64(file);

      attachments.push({
        name: name,
        content: base64,
      });
    }

    try {
      // Now, construct the messageRequest after the file has been read
      const messageRequest: MessageRequest = {
        subject: data.get('Subject'),
        body: data.get('Body'),
        attachments: attachments,
      };

      const response = await apim.replyToQuote(
        SATAIR_BASESITE_ID,
        miraklQuoteRequestId,
        quoteCode,
        userId,
        messageRequest,
      );

      return toLegacyResponse(response, {});
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }

  /**
   * (9) Quotes: Handle messages/quotes/{quoteCode}/{miraklQuoteRequestId}/{messageId}/download
   */
  public static async handleDownloadQuoteMessageDocument({
    quoteCode,
    threadId,
    messageId,
    attachment,
  }: any) {
    const userId = Cookies.get('globalId')!;

    const quoteDocuments = await apim.getAttachmentFromMessageOnQuote(
      SATAIR_BASESITE_ID,
      messageId,
      threadId,
      quoteCode,
      userId,
      {
        attachmentId: attachment.Id,
      },
    );

    const processedResponse = await toLegacyDownloadResponse(quoteDocuments, attachment.Name);

    try {
      return toLegacyResponse(quoteDocuments, {
        Data: processedResponse,
      });
    } catch (error) {
      throw legacyErrorHandler(error);
    }
  }
}
