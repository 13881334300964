<template>
  <LeasePricingDetailsTable :lease-info="leaseInfo" :product="product" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import LeasePricingDetailsTable from '@/src/market/components/lease/lease-pricing-details-table/lease-pricing-details-table.vue';
import { Product } from '@/src/core/types/api';
import { LeaseInfo } from '@/src/core/apim';

const props = defineProps<{
  leaseInfo?: LeaseInfo;
  product: Product;
}>();

const leaseInfo = computed(() => {
  return props.leaseInfo;
});
</script>
