import {
  MessageAttachmentInfo,
  Price,
  QuoteMessage,
  QuoteResponse,
  QuoteResponseEntry,
  QuoteResponses,
  Unit,
} from '@/src/core/apim/spec';
import { HybrisOfferQuoteResponses } from '../types/hybris/HybrisOfferQuoteResponses';
import { HybrisQuoteMessage } from '../types/hybris/HybrisQuoteMessage';
import { HybrisQuoteResponse } from '../types/hybris/HybrisQuoteResponse';
import { HybrisMarketplaceMessageAttachmentInfo } from '../types/hybris/HybrisMarketplaceMessageAttachmentInfo';
import { HybrisQuoteResponseEntry } from '../types/hybris/HybrisQuoteResponseEntry';
import { HybrisProductVolumePrice } from '../types/hybris/HybrisProductVolumePrice';
import { HybrisUnit } from '../types/hybris/HybrisUnit';
import { CoreQuoteMessage } from '../types/core/CoreQuoteMessage';
import { dateStringToIsoWithoutMS } from '../services/date-service';
import { MarketplaceMessageAttachmentInfo } from '../types/core/MarketplaceMessageAttachmentInfo';

/**
 * ===== From Marketplace to Hybris =====
 **/
export function mapQuoteResponsesToHybrisOfferQuoteResponses(
  input: QuoteResponses,
): HybrisOfferQuoteResponses {
  return new HybrisOfferQuoteResponses({
    messages: input.messages?.map(mapQuoteMessageToHybrisQuoteMessage) ?? [],
    quoteResponses: input.quoteResponses?.map(mapQuoteResponseToHybrisQuoteResponse) ?? [],
    subject: input.subject ?? '',
    masterShopName: input.masterShopName ?? '',
    locationDisplayName: input.locationDisplayName ?? '',
  });
}

export function mapQuoteResponseToHybrisQuoteResponse(input: QuoteResponse): HybrisQuoteResponse {
  return new HybrisQuoteResponse({
    entries: input.entries?.map(mapQuoteResponseEntryToHybrisQuoteResponseEntry),
    expirationDate: input.expirationDate,
    miraklQuoteResponseId: input.miraklQuoteResponseId,
    price: mapPriceToHybrisProductVolumePrice(input.price),
    shippingPrice: mapPriceToHybrisProductVolumePrice(input.shippingPrice),
    state: input.state,
    totalPrice: mapPriceToHybrisProductVolumePrice(input.totalPrice),
  });
}

export function mapQuoteResponseEntryToHybrisQuoteResponseEntry(
  input: QuoteResponseEntry,
): HybrisQuoteResponseEntry {
  return new HybrisQuoteResponseEntry({
    miraklQuoteResponseLineId: input.miraklQuoteResponseLineId,
    price: mapPriceToHybrisProductVolumePrice(input.price),
    priceAdditionalInfo: input['priceAdditionalInfo'] ?? '',
    priceUnit: mapUnitToHybrisUnit(input.priceUnit),
    quantity: input.quantity,
    totalPrice: mapPriceToHybrisProductVolumePrice(input.totalPrice),
  });
}

export function mapUnitToHybrisUnit(input?: Unit): HybrisUnit {
  if (!input) return new HybrisUnit();

  return new HybrisUnit({
    code: input.code,
    name: input.name,
    sapCode: input.sapCode,
  });
}

export function mapPriceToHybrisProductVolumePrice(input?: Price): HybrisProductVolumePrice {
  if (!input) return new HybrisProductVolumePrice();

  return new HybrisProductVolumePrice({
    currencyIso: input.currencyIso,
    formattedValue: input.formattedValue,
    formattedVolumeDiscountPercentage: input.formattedVolumeDiscountPercentage,
    maxQuantity: input.maxQuantity,
    minQuantity: input.minQuantity,
    priceType: input.priceType,
    value: input.value,
    volumeDiscountPercentage: input.volumeDiscountPercentage,
  });
}

export function mapQuoteMessageToHybrisQuoteMessage(input: QuoteMessage): HybrisQuoteMessage {
  return new HybrisQuoteMessage({
    body: input.body,
    createdDate: dateStringToIsoWithoutMS(input.createdDate ?? ''),
    fromType: input.fromType,
    id: input.id,
    toType: input.toType,
    attachmentInfos:
      input.attachmentInfos?.map(
        mapMessageAttachmentInfoToHybrisMarketplaceMessageAttachmentInfo,
      ) ?? [],
  });
}

export function mapMessageAttachmentInfoToHybrisMarketplaceMessageAttachmentInfo(
  input: MessageAttachmentInfo,
): HybrisMarketplaceMessageAttachmentInfo {
  return new HybrisMarketplaceMessageAttachmentInfo({
    id: input.id,
    name: input.name,
    size: input.size,
  });
}

/**
 * ===== From Hybris to Marketplace =====
 **/
export function mapHybrisQuoteMessageToQuoteMessage(input: HybrisQuoteMessage): CoreQuoteMessage {
  return new CoreQuoteMessage({
    AttachmentInfos: input.attachmentInfos.map(
      mapHybrisMarketplaceMessageAttachmentInfoToMarketplaceMessageAttachmentInfo,
    ),
    Body: input.body,
    CreatedDate: input.createdDate,
    FromType: input.fromType,
    Id: input.id,
    ToType: input.toType,
  });
}

export function mapHybrisMarketplaceMessageAttachmentInfoToMarketplaceMessageAttachmentInfo(
  data: HybrisMarketplaceMessageAttachmentInfo,
): MarketplaceMessageAttachmentInfo {
  return new MarketplaceMessageAttachmentInfo({
    id: data.id,
    name: data.name,
    size: data.size,
  });
}
