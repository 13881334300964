import { AdditionalProductInfo, AvailabilityInfo } from '@/src/core/apim';
import { RuleStatus } from '@/src/core/types/rule-status';
import { ConvertToPascalCase } from '@/src/core/utils/object';
import { WithRequired, getLegacyDateMapping } from '@/src/market/services/legacy-mapping';
import {
  getDiscountPriceAvailable,
  getPriceWithMapping,
} from '@/src/market/services/legacy-mapping-product-price';
import { getProductRuleMapping } from '@/src/market/services/legacy-mapping-product-rule';

/**
 * Maps a SAP CC additionalInfo object to an expanded version of the additionalInfo.
 *
 * @param {AdditionalProductInfo} additionalInfo - A SAP CC additionalInfo object.
 * @returns {LegacyAdditionalInfo} - An expanded version of the additionalInfo.
 */
export const getAdditionalInfoWithMapping = (additionalInfo: AdditionalProductInfo) => {
  const code = additionalInfo.id;
  const info = additionalInfo.productAdditionalInfo!;
  const basePriceWithMapping = getPriceWithMapping(info.basePrice!);
  const priceWithMapping = getPriceWithMapping(info.price!);
  const { showStock, showPrice, showOrderButton, ruleStatus } = getProductRuleMapping(
    info.productRule!,
  );

  const discountStartDate = getLegacyDateMapping(info.discountStartDate);
  const discountStartDateAvailable = Boolean(info.discountStartDate);
  const discountEndDate = getLegacyDateMapping(info.discountEndDate);
  const discountEndDateAvailable = Boolean(info.discountEndDate);

  const availabilities = info.productAvailabilities?.map((availabilityInfo: AvailabilityInfo) => ({
    ...availabilityInfo,
    warehouse: info.warehouse,
    inStock: false,
    availableDate: getLegacyDateMapping(availabilityInfo.availabilityDate),
    stockCount: availabilityInfo.quantity,
    infoStatus: availabilityInfo.infoStatus ?? '',
    unknownDeliveryDate: availabilityInfo.infoStatus === 'UNKNOWN_DELIVERYDATE',
    isAsmHidden: availabilityInfo.infoStatus === 'ASM_HIDDEN',
  }));

  let stockIndication = 'N/A';
  let isInStock = false;

  if (availabilities?.length) {
    availabilities[0].inStock = info.inStock as boolean;
    isInStock = info.inStock as boolean;

    const hasQuantity = availabilities.some((a) => Number(a.stockCount) > 0);
    const isSellerSatair = info.seller === 'satair';

    if (info.inStock) {
      stockIndication = 'In stock';
    } else if (hasQuantity) {
      if (isSellerSatair) {
        const firstWithQuantity = availabilities.find((a) => Number(a.stockCount) > 0);
        stockIndication = firstWithQuantity ? firstWithQuantity.availableDate : 'N/A';
      } else {
        stockIndication = 'In stock';
        isInStock = true;
      }
    }
  }

  const isAsmHidden = availabilities?.[0]?.infoStatus === 'ASM_HIDDEN';
  const unknownDeliveryDate = availabilities?.[0]?.infoStatus === 'UNKNOWN_DELIVERYDATE';
  const discountPriceAvailable = getDiscountPriceAvailable(info);

  const enableCustomizeWarehouse = info.productRule?.sellable === RuleStatus.YES;
  const slowMoverPriceAvailable = Boolean(info.zproPriceAvailable);
  const offerSku = additionalInfo.id?.toLocaleLowerCase().includes('offer')
    ? additionalInfo.id
    : undefined;
  const quotable = info.productRule?.quotable === RuleStatus.YES;

  const toReturn = {
    ...additionalInfo,
    ...info,
    code,
    basePrice: basePriceWithMapping,
    price: priceWithMapping,
    quantity: additionalInfo?.quantity || 0,

    showStock,
    showPrice,
    showOrderButton,
    ruleStatus,

    discountStartDate,
    discountStartDateAvailable,
    discountEndDate,
    discountEndDateAvailable,

    availabilities,

    isAsmHidden,
    unknownDeliveryDate,
    isInStock,
    stockIndication,

    remainingOfferQuantity: info.remainingOfferQuantity ?? 0,

    discountPercentage: info.discountPercentage ?? 0,
    discountPercentageFormatted: info.discountPercentageFormatted ?? '',
    discountPriceAvailable,

    enableCustomizeWarehouse,
    slowMoverPriceAvailable,
    offerSku,
    quotable,

    hasMultipleOffers: false,

    isBusy: false,
    hasFailed: false,
  };

  return toReturn as WithRequired<typeof toReturn, 'id' | 'basePrice'>;
};

export type AdditionalInfoWithMapping = ReturnType<typeof getAdditionalInfoWithMapping>;
export type LegacyAdditionalInfo = ConvertToPascalCase<AdditionalInfoWithMapping>;
