import { OwningSystem, Quote, QuoteDetail, QuoteStatus } from '@/src/core/types/api';
import { useQuotesStore } from '@/src/market/stores/quotes';
import { AIRBUS_SELLER_KEY } from '@/src/market/utils/seller-key';

export const isQuoteReady = (state: string) => {
  const quotesStore = useQuotesStore();

  return (
    state === 'COMPLETED' ||
    (quotesStore.satairQuotesEnabled &&
      (state === 'PARTIALLY_READY' || state === 'PARTIALLY_ACCEPTED'))
  );
};

export const shouldHidePricesForQuote = (quoteDetails: QuoteDetail) => {
  return (
    quoteDetails.OwningSystem === OwningSystem.BLUE &&
    (!quoteDetails.TotalPrice ||
      quoteDetails.State === QuoteStatus.CANCELLED ||
      quoteDetails.State === QuoteStatus.EXPIRED ||
      !quoteDetails.SapQuoteId ||
      quoteDetails.QuoteDateExpired)
  );
};

export const getQuoteStatus = (quote: QuoteDetail | Quote, fromQuoteList = false) => {
  // Use the default quote status for Satair & Mirakl
  if (
    (fromQuoteList && quote.MasterShop?.Id?.toLowerCase() !== AIRBUS_SELLER_KEY) ||
    (!fromQuoteList && quote.OwningSystem !== OwningSystem.BLUE)
  ) {
    return quote.State;
  }

  // UX requirements for Airbus quote status
  // Some of this logic might not be needed since it's
  // quite likely that the status for these scenarios
  // will be the same as the default quote status
  if (!quote.SapQuoteId) {
    return QuoteStatus.SUBMITTED;
  }

  if (
    quote.SapQuoteId &&
    quote.State !== QuoteStatus.CANCELLED &&
    quote.State !== QuoteStatus.EXPIRED &&
    (!quote.TotalPrice || quote.TotalPrice?.Value === '0')
  ) {
    return QuoteStatus.CREATED;
  }

  if (
    quote.SapQuoteId &&
    quote.State !== QuoteStatus.CANCELLED &&
    quote.State !== QuoteStatus.EXPIRED &&
    quote.TotalPrice
  ) {
    return QuoteStatus.COMPLETED;
  }

  return quote.State;
};
