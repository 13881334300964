export interface IHybrisProductVolumePrice {
  currencyIso: string;
  formattedValue: string;
  formattedVolumeDiscountPercentage: string;
  maxQuantity: number;
  minQuantity: number;
  priceType: string;
  value: number;
  volumeDiscountPercentage: number;
}

export class HybrisProductVolumePrice implements IHybrisProductVolumePrice {
  currencyIso: string;
  formattedValue: string;
  formattedVolumeDiscountPercentage: string;
  maxQuantity: number;
  minQuantity: number;
  priceType: string;
  value: number;
  volumeDiscountPercentage: number;

  constructor(init?: Partial<HybrisProductVolumePrice>) {
    Object.assign(this, init);
  }
}
