import { object, string, minLength, maxLength, custom, forward } from 'valibot';

export enum PasswordViolationErrors {
  REQUIRED = 'password_required',
  MIN_LENGTH = 'password_min_Length',
  MAX_LENGTH = 'password_max_Length',
  OLD_NEW_PASSWORD_SAME = 'password_old_new_same',
  PASSWORD_EMAIL_SAME = 'password_email_same',
  CONTAINS_AIRBUS_SATAIR = 'password_contains_airbus_satair',
}

const passwordCannotContainAirbusOrSatair = (password: string): boolean => {
  const forbiddenWords = ['airbus', 'satair'];
  return !forbiddenWords.some((word) => password.toLowerCase().includes(word));
};

const passwordCannotContainEmail = (password: string, email: string): boolean => {
  return !password.toLowerCase().includes(email.toLowerCase());
};

export const PasswordPolicySchema = object(
  {
    email: string([minLength(1)]),
    oldPassword: string([minLength(1, PasswordViolationErrors.REQUIRED)]),
    newPassword: string([
      minLength(12, PasswordViolationErrors.MIN_LENGTH),
      maxLength(128, PasswordViolationErrors.MAX_LENGTH),
      custom(
        (input) => passwordCannotContainAirbusOrSatair(input),
        PasswordViolationErrors.CONTAINS_AIRBUS_SATAIR,
      ),
    ]),
  },
  [
    forward(
      custom(
        ({ oldPassword, newPassword }) => oldPassword !== newPassword,
        PasswordViolationErrors.OLD_NEW_PASSWORD_SAME,
      ),
      ['newPassword'],
    ),
    forward(
      custom(
        ({ email, newPassword }) => passwordCannotContainEmail(newPassword, email),
        PasswordViolationErrors.PASSWORD_EMAIL_SAME,
      ),
      ['newPassword'],
    ),
  ],
);
