import { HybrisMarketplaceMessageAttachmentInfo } from './HybrisMarketplaceMessageAttachmentInfo';

export class HybrisMarketplaceMessage {
  authorName?: string;
  authorType?: string;
  body?: string;
  createdDate?: string;
  subject?: string;
  attachmentInfos?: HybrisMarketplaceMessageAttachmentInfo[];

  constructor(init?: Partial<HybrisMarketplaceMessage>) {
    Object.assign(this, init);
  }
}
