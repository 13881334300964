<template>
  <StandardDialogModal
    class="modal-request-quote-fhs"
    :size="uiSize.Large"
    :loading="isBusy"
    action-button-alignment="right"
  >
    <template #header>
      <ModalRequestQuoteHeader
        :offer-id="id"
        :header-title="textK('UI_FHS_QUOTATION_QUOTE_HEADER')"
      />
    </template>
    <template #body>
      <p class="pb-xl">
        {{ textK('UI_FHS_QUOTATION_QUOTE_DESCRUPTION') }}
      </p>
      <div class="flex gap-md pb-lg">
        <VSelect
          class="modal-request-quote-fhs__location"
          :options="locations"
          label="Location"
          :required="true"
          :type="uiVariant.Secondary"
          v-model="location"
        />
        <QuantitySelector
          class="modal-request-quote-fhs__quantity"
          :avoid-zero-value="true"
          as-input
          :min-value="MIN_QUANTITY_VALUE"
          label="Quantity"
          v-model="formData.requestedQuantity"
          unit="Quanity"
          is-fhs
          :with-delay="false"
        />
      </div>
      <div>
        <VTextField
          class="pb-xl"
          v-model="formData.quotationReference"
          input-type="text"
          :type="uiVariant.Secondary"
          :label="textK('UI_FHS_QUOTATION_REFERENCE_TEXT')"
          name="referenceNumber"
          :required="true"
          :vmax-length="MAX_REFERENCE_LENGTH"
          regex="^[A-Za-z0-9\s\-]+$"
        />
        <VTextarea
          class="pb-l"
          v-model="formData.remarks"
          :vmax-length="MAX_MESSAGE_LENGTH"
          :type="uiVariant.Secondary"
          :label="textK('UI_FHS_QUOTATION_MESSAGE')"
        />
      </div>
    </template>
    <template #actions>
      <div>
        <VButton
          @click.prevent="onSubmit"
          :disabled="!isValid"
          :loading="isBusy"
          :type="uiVariant.Default"
          v-ui-test="'submit-aribus-fhs-quote'"
          >{{ textK('UI_FHS_QUOTATION_BUTTON_LABEL') }}
        </VButton>
      </div>
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-request-quote-fhs.scss" lang="scss" scoped></style>
<script setup lang="ts">
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { VSelect } from '@/src/core/components/ui/form/select/select.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import VTextarea from '@/src/core/components/ui/form/textarea/textarea.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { Req } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';
import { SendQuoteEmailToAirbusRequest } from '@/src/core/types/api';
import { QuoteApi } from '@/src/market/api/quoteApi';
import ModalRequestQuoteHeader from '@/src/market/components/modals/modal-request-quote/modal-request-quote-header/modal-request-quote-header.vue';
import QuantitySelector from '@/src/market/components/ui/quantity-selector/quantity-selector.vue';
import { useProductFHSDetailsStore } from '@/src/market/stores/product-fhs-details';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, reactive, ref } from 'vue';

interface Params {
  id: string;
  procurementType: string;
}

interface Form {
  quotationReference: string;
  remarks?: string;
  requestedQuantity: number;
  location: string;
}

const MAX_MESSAGE_LENGTH = 1000;
const MAX_REFERENCE_LENGTH = 11;
const MIN_QUANTITY_VALUE = 1;

const textK = useText();
const modalStore = useModalStore();
const uiSize = UiSize;
const uiVariant = UiVariant;
const userStore = useUserStore();
const fhsDetailsStore = useProductFHSDetailsStore();

const formData = reactive<Form>({
  quotationReference: '',
  requestedQuantity: MIN_QUANTITY_VALUE,
  location: '',
});
const isBusy = ref(false);
const isInputFocused = ref(false);
const isValid = computed(
  () =>
    formData.quotationReference.length > 0 &&
    formData.location &&
    !isInputFocused.value &&
    formData.requestedQuantity >= MIN_QUANTITY_VALUE,
);
const id = computed(() => (modalStore.params as Params)?.id ?? '');
const procurementType = computed(
  () => (modalStore.params as Params)?.procurementType.toUpperCase() ?? '',
);

const fshDetailsByProcurement = computed(() =>
  fhsDetailsStore.getFhsDetailsByProductIdAndProcurement(id.value, procurementType.value),
);
const locations = computed(() => fshDetailsByProcurement.value?.locations ?? []);
const supplier = computed(() => fshDetailsByProcurement.value?.supplier);

const location = computed({
  get: () => locations.value.indexOf(formData.location),
  set: (index: number) => {
    formData.location = locations.value[index];
  },
});

const closeModal = () => {
  modalStore.closeModal();
};

const onSubmit = async () => {
  isBusy.value = true;

  const { Name: companyData } = userStore.company;
  const { Name: customerName, Email: customerEmail } = userStore.currentUser;

  const requestObejct: SendQuoteEmailToAirbusRequest = {
    ...formData,
    customerName,
    customerEmail,
    companyData,
    productId: id.value,
    procurementType: procurementType.value,
    supplier: supplier.value,
  };

  const { IsSuccess } = await Req({
    url: QuoteApi.CreateAirbusQuoteFHS,
    method: 'POST',
    data: requestObejct,
  });

  if (IsSuccess) {
    modalStore.showModal({
      modalComponent: 'ModalQuoteEmailNotification',
      params: {
        key: `AirbusFHSConfirmationFHSquotes_ModalUrl`,
        title: `AirbusFHSConfirmationFHSquotes_Name`,
        size: uiSize.Medium,
        isConfirmation: true,
        withCloseButton: true,
      },
    });

    closeModal();
  }

  isBusy.value = false;
};
</script>
