<template>
  <div class="content-wrapper">
    <transition
      name="custom-classes-transition"
      @enter="resetScrollPos"
      @after-enter="checkForAnchor"
      leave-active-class="page-transition-animated fadeOutTranslateUp"
      mode="out-in"
    >
      <Component :is="component"></Component>
    </transition>
    <Spinner v-if="isBusy" :dark-theme="true" :has-overlay="true"></Spinner>
  </div>
</template>

<style src="./content-loader.scss" lang="scss"></style>

<script lang="ts" setup>
import {
  type Component,
  compile,
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { useContentStore } from '@/src/content/stores/content';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { Site, useUIStore } from '@/src/core/stores/ui';
import { IWindowServerRendered } from '@/src/core/types/ui';

const authenticationStore = useAuthenticationStore();
const uiStore = useUIStore();
const route = useRoute();
const contentStore = useContentStore();
const site = Site;

const isBusy = computed(() => {
  return contentStore.isBusy;
});

const isAuthenticated = computed(() => {
  return authenticationStore.isAuthenticated;
});

const resetScrollPos = () => {
  if (!route?.hash) {
    window.scrollTo(0, 0);
  }
};

const checkForAnchor = () => {
  nextTick(() => {
    if (route?.hash) {
      setTimeout(() => {
        window.scrollTo(0, document.querySelector(route.hash)?.getBoundingClientRect().top || 0);
      }, 50);
    }
  });
};

const createContent = () => {
  const srContent: HTMLElement | undefined = (window as unknown as IWindowServerRendered).srContent;

  if (srContent) {
    contentStore.setUmbracoContent({
      umbracoContent: srContent.outerHTML,
      contentURL: srContent.getAttribute('sr-content') || '',
    });
    (window as unknown as IWindowServerRendered).srContent = undefined;
  } else {
    contentStore.fetchUmbracoContent({ contentURL: route?.fullPath || '' });
  }
};

const content = computed(() => {
  return contentStore.umbracoContent;
});

const component = computed(
  () => (content.value ? { render: compile(content.value) } : null) as Component,
);

watch(
  () => route.fullPath,
  (to: string, from: string) => {
    if (uiStore.site === site.Content && to !== from) {
      contentStore.fetchUmbracoContent({ contentURL: route.fullPath || '' });
    }
  },
  { deep: true, immediate: true },
);

onMounted(() => {
  contentStore.fetchUmbracoContent({ contentURL: route?.fullPath || '', force: true });
  createContent();
});

watch(isAuthenticated, () => {
  contentStore.fetchUmbracoContent({ contentURL: route?.fullPath || '', force: true });
  1;
});

onBeforeUnmount(() => {
  contentStore.reset();
});
</script>
