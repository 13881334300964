export interface IHybrisMarketplaceMessageAttachmentInfo {
  id: string;
  name: string;
  size: number;
}

export class HybrisMarketplaceMessageAttachmentInfo
  implements IHybrisMarketplaceMessageAttachmentInfo
{
  id: string;
  name: string;
  size: number;

  constructor(init?: Partial<IHybrisMarketplaceMessageAttachmentInfo>) {
    Object.assign(this, init);
  }
}
