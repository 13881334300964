import { UserApi } from '@/src/core/api/userApi';
import useText from '@/src/core/hooks/useText';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { sanitizeUser } from '@/src/core/services/user';
import { useAuthenticationStore } from '@/src/core/stores/authentication';
import { useModalStore } from '@/src/core/stores/modal';
import { useUIStore } from '@/src/core/stores/ui';
import { B2BUnit, User, UserProfile } from '@/src/core/types/api';
import { IPermissions, IProfile, IUser } from '@/src/core/types/interfaces';
import { useCartStore } from '@/src/market/stores/cart';
import { useCheckoutStore } from '@/src/market/stores/checkout';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useUserStore = defineStore('user', () => {
  const uiStore = useUIStore();
  const modalStore = useModalStore();
  const cartStore = useCartStore();
  const checkoutStore = useCheckoutStore();
  const profile = ref<UserProfile>({} as UserProfile);
  const permissions = ref<IPermissions>({} as IPermissions);
  const isBusy = ref<boolean>(false);
  const cookiesAccepted = ref<boolean>(false);

  const currentUser = computed(() => profile.value?.User ?? {});

  const accountGroup = computed(() => profile.value?.User?.Unit?.BlueB2BUnit?.AccountGroup);

  const linkedAccounts = computed(() => profile.value?.User?.LinkedAccounts || []);

  const currentAccount = computed(() => {
    return linkedAccounts.value?.find((account) => account.CurrentAccount);
  });
  const sortedLinkedAccounts = computed(() => {
    return linkedAccounts.value?.reduce((accumulated, account) => {
      if (account.CurrentAccount) {
        return [account, ...accumulated];
      }
      return [...accumulated, account];
    }, []);
  });

  const accountId = computed(() => currentUser.value?.DefaultUnitUid);

  const globalId = computed(() => currentUser.value?.GlobalId);

  const company = computed(() => profile.value?.Company);

  const redB2BUnit = computed(() => profile.value.User?.Unit?.RedB2BUnit);

  const blueB2BUnit = computed(() => profile.value.User?.Unit?.BlueB2BUnit);

  const redCustomer = computed(() => {
    if (!profile.value.User && isBusy.value) {
      return undefined;
    }
    return profile.value.User?.RedCustomer;
  });

  const blueCustomer = computed(() => {
    if (!profile.value.User && isBusy.value) {
      return undefined;
    }
    return profile.value.User?.BlueCustomer;
  });

  const provisionedByAirbusWorld = computed(() => {
    if (!profile.value.User && isBusy.value) {
      return false;
    }
    return profile.value.User?.ProvisionedByAirbusWorld;
  });

  const airbusLegalName = computed(() => {
    const textK = useText();
    const US = 'US';
    const NON_US = 'NON_US';

    if (accountGroup.value === US) {
      return textK('UI_COMMON_LEGAL_NAME_AIRBUS_3000');
    } else if (accountGroup.value === NON_US) {
      return textK('UI_COMMON_LEGAL_NAME_AIRBUS_1000');
    }

    return 'Airbus';
  });

  const mixedCustomer = computed(() => blueCustomer.value && redCustomer.value);

  const isHardenPasswordPolicyEnabled = computed(() => {
    return hasFeature(FEATURES.HARDEN_PASSWORD_POLICY);
  });

  const currentUserProfileUpdated = (payload: { profile: IProfile }) => {
    profile.value = payload.profile;
  };

  const currentUserUpdated = (payload: { user: IUser }) => {
    profile.value.User = { ...profile.value.User, ...payload.user };
  };

  const permissionUpdated = (payload: { permissions: IPermissions }) => {
    permissions.value = { ...permissions.value, ...payload.permissions };
  };

  const companyUpdated = (payload: { company: B2BUnit }) => {
    profile.value.Company = { ...profile.value.Company, ...payload.company };
  };

  const cookiesAcceptUpdated = () => {
    cookiesAccepted.value = true;
  };

  const reset = () => {
    profile.value = {} as UserProfile;
    cookiesAccepted.value = false;
    isBusy.value = false;
  };

  // FULL PROFILE UPDATE
  const fetchUserProfile = async () => {
    const authenticationStore = useAuthenticationStore();
    isBusy.value = true;
    const { IsSuccess, Data } = await Req({
      url: UserApi.GetUserProfile(authenticationStore.getGlobalId),
    });

    if (IsSuccess && Data) {
      currentUserProfileUpdated({ profile: { ...Data, User: sanitizeUser(Data.User) } });

      if (Data.User) {
        authenticationStore.updateCompanyCookies({
          isAdmin: Data.User.IsAdmin,
          isReadOnly: Data.User.IsReadOnly,
          isFinance: Data.User.IsFinance,
          betaCustomer: Data.User.BetaCustomer,
          readOnlyAccount: Data.User.ReadOnlyAccount,
        });
      }
    }
    isBusy.value = false;
  };

  const fetchUser = async (payload: { userId: string }) => {
    isBusy.value = true;
    const { IsSuccess, Data } = await Req({
      url: UserApi.GetUserDetails(payload.userId),
    });

    if (IsSuccess) {
      currentUserUpdated({ user: Data });
    }
    isBusy.value = false;
  };

  const fetchPermissions = async () => {
    isBusy.value = true;
    const { Data, IsSuccess } = await Req({
      url: UserApi.PermissionDetails,
    });
    if (IsSuccess) {
      permissionUpdated({ permissions: Data });
    }
    isBusy.value = false;
    return { IsSuccess };
  };

  const fetchCompany = async (payload: { userId: string }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req(
      {
        url: UserApi.GetUserUnit(payload.userId),
      },
      undefined,
      UserApi.handleGetUserUnit.bind(UserApi, { id: payload.userId }),
    );

    if (Data && IsSuccess) {
      companyUpdated({ company: Data });
    }
    isBusy.value = false;
  };

  const updateUser = async (payload: { userId: string; user: User }) => {
    isBusy.value = true;

    const { IsSuccess } = await Req({
      method: 'POST',
      url: UserApi.UpdateUserDetails(payload.userId, false),
      data: payload.user,
    });

    if (IsSuccess) {
      const authenticationStore = useAuthenticationStore();
      authenticationStore.isFirstTimeLogin({ isFirstTime: false });
      fetchUserProfile();
    } else {
      fetchUserProfile();
    }
    isBusy.value = false;

    return { IsSuccess };
  };

  const updatePermissions = async (payload: { permissions: IPermissions }) => {
    isBusy.value = true;

    const permissionData = {
      Permission: payload.permissions,
    };
    const { IsSuccess } = await Req(
      {
        method: 'POST',
        url: UserApi.PermissionDetails,
        data: permissionData,
      },
      new ReqQueue(ReqQueueTypes.LastResponse, 'permissions'),
    );

    if (IsSuccess) {
      permissionUpdated({ permissions: payload.permissions });
    }

    isBusy.value = false;

    return { IsSuccess };
  };

  const updateUserEmail = async (payload: { userId: string; password: string; email: string }) => {
    isBusy.value = true;

    const { IsSuccess } = await Req({
      url: UserApi.UpdateUserLogin(payload.userId),
      method: 'POST',
      data: { Password: payload.password, Email: payload.email },
    });

    if (IsSuccess) {
      fetchUser(payload);
    }
    isBusy.value = false;

    return { IsSuccess };
  };

  const acceptTOU = async () => {
    isBusy.value = true;
    const { IsSuccess } = await Req({
      url: UserApi.AcceptTermsOfUse,
      method: 'POST',
      data: { email: profile.value.User.Email },
    });

    if (IsSuccess) {
      const authenticationStore = useAuthenticationStore();
      authenticationStore.hasAcceptedTermsOfUse();
    }
    isBusy.value = false;
    return { IsSuccess };
  };

  const releaseNotesSeen = async () => {
    isBusy.value = true;

    const { IsSuccess } = await Req({
      url: UserApi.ReleaseNotesSeen,
      method: 'POST',
      data: { email: profile.value.User.Email },
    });

    if (IsSuccess) {
      const authenticationStore = useAuthenticationStore();
      authenticationStore.hasAcceptedReleaseNotesAction();
    }
    isBusy.value = false;

    return { IsSuccess };
  };

  const acceptCookies = () => {
    cookiesAcceptUpdated();
  };

  const switchAccount = async (payload: { user: User; unitUid: string; unitName: string }) => {
    isBusy.value = true;

    uiStore.showContentOverlay({ showOverlay: true, showLoader: true });

    const { IsSuccess } = await Req({
      method: 'POST',
      url: UserApi.SwitchUserAccount(payload.user.GlobalId, payload.unitUid),
      data: { UnitName: payload.unitName },
    });

    if (IsSuccess) {
      const authenticationStore = useAuthenticationStore();
      // clearCriticalStores(); Avoid circular dependency needs to be refactor
      //clearCriticalStores();
      authenticationStore.isFirstTimeLogin({ isFirstTime: false });
    }

    isBusy.value = false;
    uiStore.showContentOverlay({ showOverlay: false });

    return { IsSuccess };
  };

  const switchAccountDetect = async (payload: {
    user: User;
    unitUid: string;
    unitName: string;
  }) => {
    if (checkoutStore.quoteCode) {
      modalStore.showModal({ modalComponent: 'ModalDisableAccountSwitch' });
      return true;
    } else if (
      cartStore.currentCart?.Entries?.length > 0 &&
      Cookies.get('disable-switch-account-confirmation') !== 'true'
    ) {
      modalStore.showModal({
        modalComponent: 'ModalSwitchAccount',
        params: { unitUid: payload.unitUid, unitName: payload.unitName },
      });
      return true;
    } else {
      return await switchAccount(payload);
    }
  };

  return {
    globalId,
    profile,
    permissions,
    isBusy,
    cookiesAccepted,
    currentUser,
    accountGroup,
    linkedAccounts,
    currentAccount,
    sortedLinkedAccounts,
    accountId,
    company,
    redB2BUnit,
    blueB2BUnit,
    redCustomer,
    blueCustomer,
    mixedCustomer,
    provisionedByAirbusWorld,
    airbusLegalName,
    isHardenPasswordPolicyEnabled,
    reset,
    fetchUserProfile,
    fetchPermissions,
    fetchCompany,
    updateUser,
    updatePermissions,
    updateUserEmail,
    acceptTOU,
    releaseNotesSeen,
    acceptCookies,
    switchAccount,
    switchAccountDetect,
  };
});
