import { OrderThreads } from '@/src/core/apim';
import { HybrisMarketplaceMessagingThreads } from '../types/hybris/HybrisMarketplaceMessagingThreads';
import {
  mapHybrisMessagePaginationToPagination,
  mapMessageThreadToHybrisMarketplaceMessagingThread,
} from './offer-mappings';
import { HybrisMarketplaceMessagingThread } from '../types/hybris/HybrisMarketplaceMessagingThread';
import { MarketplaceMessagingThread } from '../types/core/MarketplaceMessagingThread';
import { HybrisMarketplaceMessage } from '../types/hybris/HybrisMarketplaceMessage';
import { MarketplaceMessage } from '../types/core/MarketplaceMessage';
import { mapHybrisMarketplaceMessageAttachmentInfoToMarketplaceMessageAttachmentInfo } from './quote-mappings';
import { MarketplaceMessagingThreads } from '../types/core/MarketplaceMessagingThreads';
import { dateStringToIsoWithoutMS } from '../services/date-service';

/**
 * ===== From Marketplace to Hybris =====
 **/
export function mapOrderThreadsToHybrisMarketplaceMessagingThreads(
  data: OrderThreads,
): HybrisMarketplaceMessagingThreads {
  return new HybrisMarketplaceMessagingThreads({
    orderCode: data.orderCode,
    threads: data.threads?.map(mapMessageThreadToHybrisMarketplaceMessagingThread),
  });
}

/**
 * ===== From Hybris to Marketplace =====
 **/
export function mapHybrisMarketplaceMessagingThreadToMarketplaceMessagingThread(
  data: HybrisMarketplaceMessagingThread,
): MarketplaceMessagingThread {
  return new MarketplaceMessagingThread({
    createdDate: data.createdDate,
    locationDisplayName: data.locationDisplayName,
    masterShopName: data.masterShopName,
    messages: data.messages,
    pagination: mapHybrisMessagePaginationToPagination(data.pagination),
    subject: data.subject,
    threadId: data.threadId,
    totalMessageCount: data.totalMessageCount,
    updatedDate: data.updatedDate,
  });
}

export function mapHybrisMarketplaceMessagingThreadsToMarketplaceMessagingThreads(
  data: HybrisMarketplaceMessagingThreads,
): MarketplaceMessagingThreads {
  return new MarketplaceMessagingThreads({
    orderCode: data.orderCode,
    threads: data.threads.map(mapHybrisMarketplaceMessagingThreadToMarketplaceMessagingThread),
  });
}

export function mapHybrisMaketplaceMessageToMarketplaceMessage(
  data?: HybrisMarketplaceMessage,
): MarketplaceMessage {
  return new MarketplaceMessage({
    attachmentInfos: data?.attachmentInfos?.map(
      mapHybrisMarketplaceMessageAttachmentInfoToMarketplaceMessageAttachmentInfo,
    ),
    authorName: data?.authorName,
    authorType: data?.authorType,
    body: data?.body,
    createdDate: dateStringToIsoWithoutMS(data?.createdDate),
    subject: data?.subject,
  });
}
