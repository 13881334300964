import { computed, WritableComputedRef } from 'vue';

export const createDateRange = (
  fromDate: WritableComputedRef<string | undefined, string | undefined>,
  toDate: WritableComputedRef<string | undefined, string | undefined>,
) => {
  const getDate = () => {
    return fromDate.value + ' - ' + toDate.value;
  };

  const setDate = (value: string) => {
    if (Array.isArray(value)) {
      fromDate.value = value[0];
      toDate.value = value[1];
    } else {
      const values = value?.split(' - ');
      if (values?.length > 1) {
        fromDate.value = values[0];
        toDate.value = values[1];
      }
    }
  };

  return computed({
    get: getDate,
    set: setDate,
  });
};

export const checkIfDateIsValid = (d?: Date) => d instanceof Date && !isNaN(d.getTime());
