<template>
  <div class="product__detail">
    <p class="product__detail__label">Condition</p>
    <p v-if="product" class="product__detail__value">{{ condition }}</p>
    <SkeletonLoader v-else size="0" />
  </div>
</template>

<script lang="ts" setup>
import SkeletonLoader from '@/src/core/components/ui/skeleton-loader/skeleton-loader.vue';
import { Product } from '@/src/core/types/api';
import { Procurement } from '@/src/core/types/interfaces';
import { getProcurementCondition } from '@/src/market/services/procurements';
import {
  isAirbus,
  productAdditionalData as productAdditionalDataUtil,
} from '@/src/market/services/product-parts';
import { computed } from 'vue';

interface Props {
  product: Product;
  procurementType?: string | undefined;
  procurement?: Procurement;
}

const props = defineProps<Props>();
const product = computed(() => props.product);
const procurementType = computed(() => props.procurementType);

const productAdditionalData = computed(() => {
  return productAdditionalDataUtil(product.value.Id, product.value.OwningSystem);
});

const condition = computed(() => {
  if (isAirbus(productAdditionalData.value) && procurementType.value && props.procurement) {
    return getProcurementCondition(props.procurement);
  }

  return product.value.State || 'New';
});
</script>
