import { OrderType, OrderTypeOption } from '@/src/core/apim';
import { objectKeysToPascalCaseRecursive } from '@/src/core/utils/object';

export const getOrderTypeWithMapping = (ordertype: OrderType) => {
  return objectKeysToPascalCaseRecursive({
    ...ordertype,
  } as Required<OrderType>);
};

export type LegacyOrderType = ReturnType<typeof getOrderTypeWithMapping>;

export const getOrderTypeOptionWithMapping = (orderTypeOption: OrderTypeOption) => {
  return objectKeysToPascalCaseRecursive({
    ...orderTypeOption,
  } as Required<OrderTypeOption>);
};

export type LegacyOrderTypeOption = ReturnType<typeof getOrderTypeOptionWithMapping>;
