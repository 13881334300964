export interface IHybrisUnit {
  code: string;
  name: string;
  sapCode: string;
}

export class HybrisUnit implements IHybrisUnit {
  code: string;
  name: string;
  sapCode: string;

  constructor(init?: Partial<HybrisUnit>) {
    Object.assign(this, init);
  }
}
