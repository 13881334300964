import { Req } from '@/src/core/services/requester';
import { ServiceOrder } from '@/src/core/types/api';
import {
  IRepairExchangeOrderListFilters,
  IRepairExchangeOrderListQuery,
} from '@/src/core/types/interfaces';
import { toHybrisFormat } from '@/src/core/utils/dates';
import { OrderApi } from '@/src/market/api/orderApi';
import dayjs from 'dayjs';
import { defineStore } from 'pinia';

interface RepairExchangeOrdersState {
  isBusy: boolean;
  totalCount: number;
  orders: ServiceOrder[];
  orderDetails: ServiceOrder;
  filters: IRepairExchangeOrderListFilters;
}

export const useRepairExchangeOrdersStore = defineStore('repairExchangeOrders', {
  state: (): RepairExchangeOrdersState => ({
    isBusy: false,
    totalCount: 0,
    orders: [],
    orderDetails: {} as ServiceOrder,
    filters: {} as IRepairExchangeOrderListFilters,
  }),
  actions: {
    async fetchRepairAndExchangeOrders(): Promise<{ IsSuccess: boolean }> {
      this.setIsBusy({ isBusy: true });

      const params: IRepairExchangeOrderListQuery = { ...this.filters };

      // default search period should be the last 3 years
      if (!params.fromDate) {
        params.fromDate = toHybrisFormat(dayjs().subtract(36, 'month'));
      }
      if (!params.toDate) {
        params.toDate = toHybrisFormat(dayjs());
      }

      const { IsSuccess, Data } = await Req(
        {
          url: OrderApi.RepairExchangeOrders,
          method: 'POST',
          data: params,
        },
        undefined,
        OrderApi.handleRepairExchangeOrders,
      );

      if (IsSuccess && Data) {
        this.orders = Data;

        if (this.totalCount === 0) {
          this.totalCount = Data.length;
        }
      }

      this.setIsBusy({ isBusy: false });

      return { IsSuccess };
    },
    async fetchRepairAndExchangeOrderDetails(payload: { orderNumber: string }) {
      this.setIsBusy({ isBusy: true });

      const { IsSuccess, Data } = await Req(
        {
          url: OrderApi.RepairExchangeOrderDetails(payload.orderNumber),
          method: 'GET',
          data: { orderNumber: payload.orderNumber },
        },
        undefined,
        OrderApi.handleRepairExchangeOrderDetails,
      );

      if (IsSuccess && Data) {
        this.orderDetails = Data;
      }

      this.setIsBusy({ isBusy: false });

      return { IsSuccess };
    },
    setIsBusy(payload: { isBusy: boolean }) {
      this.isBusy = payload.isBusy;
    },
    clearOrderDetails() {
      this.orderDetails = {} as ServiceOrder;
    },
    updateOrderListFilters(payload: { Query: IRepairExchangeOrderListFilters }) {
      this.filters = Object.assign({}, this.filters, payload.Query);
    },
    deleteOrderListFilter(payload: { Key: string }) {
      delete this.filters[payload.Key];
      this.filters = {
        ...this.filters,
      };
    },
    clearFilterGroup(group: readonly string[]) {
      for (const key of group) {
        delete this.filters[key];
      }

      this.filters = {
        ...this.filters,
      };
    },
    clearFilters() {
      this.filters = {};
    },
    clearOrders() {
      this.orders = [];
      this.totalCount = 0;
    },
  },
});
