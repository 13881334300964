<template>
  <StandardDialogModal
    class="list-modal"
    :size="uiSize.Large"
    :loading="!isFetched || changingAddress"
  >
    <template #header>
      <div>
        <h3>{{ textK('UI_COMMON_DELIVERY_ADDRESS') }}</h3>
        <p>{{ textK('UI_QUOTES_CANNOT_CHANGE_ADDRESS') }}</p>
      </div>
    </template>
    <template #body>
      <ModalColumnContainer>
        <ModalColumn>
          <AddressSelector
            v-model="selected"
            @isFetched="addressesUpdated"
            :is-marketplace="isMarketplace"
          />
        </ModalColumn>
      </ModalColumnContainer>
    </template>
    <template #actions>
      <VButton :type="uiVariant.Secondary" @click="back">{{
        textK('UI_COMMON_BACK_BUTTON')
      }}</VButton>
      <VButton :type="uiVariant.Default" :disabled="!isValid || isBusy" @click="submitQuote">
        {{
          hasPrice
            ? textK('UI_QUOTES_REQUEST_EXISTING_PRICE_TITLE')
            : textK('UI_QUOTES_REQUEST_CTA')
        }}
      </VButton>
    </template>
  </StandardDialogModal>
</template>

<style lang="scss" src="./modal-request-quote-select-address.scss" scoped></style>

<script setup lang="ts">
import ModalColumnContainer from '@/src/core/components/modals/ui/column-layout/modal-column-container.vue';
import ModalColumn from '@/src/core/components/modals/ui/column-layout/single-column/modal-column.vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useAdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { useModalStore } from '@/src/core/stores/modal';
import { Address, OfferQuoteRequest } from '@/src/core/types/api';
import { IModalRequestQuoteParams } from '@/src/market/components/modals/modal-request-quote/modal-request-quote.vue';
import AddressSelector from '@/src/market/components/ui/address-selector/address-selector.vue';
import { useDeliveryAddressesQuotesStore } from '@/src/market/stores/delivery-addresses-quotes';
import { useProductStore } from '@/src/market/stores/product';
import { useProductPriceInfoStore } from '@/src/market/stores/product-price-info';
import { useQuotesRequestStore } from '@/src/market/stores/quotes-request';
import { computed, onMounted, ref } from 'vue';

const textK = useText();
const productPriceInfoStore = useProductPriceInfoStore();
const modalStore = useModalStore();
const deliveryAddressesQuote = useDeliveryAddressesQuotesStore();
const createQuoteStore = useQuotesRequestStore();
const productStore = useProductStore();

const changingAddress = ref<boolean>(false);
const restrictedAddress = ref<boolean>(true);
const isFetched = ref<boolean>(false);
const uiVariant = UiVariant;
const uiSize = UiSize;

const params = computed(() => {
  return modalStore.params as IModalRequestQuoteParams;
});

const quoteId = computed(() => {
  return params.value.id;
});

const sellerId = computed(() => {
  return params.value.sellerId;
});

const isMarketplace = computed(() => {
  return params.value.isMarketplace;
});

const fetchDeliveryAddress = async () => {
  await deliveryAddressesQuote.fetchDeliveryAddresses({ sellerId: sellerId.value });
};

const addressesUpdated = () => {
  if (!isFetched.value) {
    isFetched.value = true;
  }
};

const updateIsShippingAddressRestricted = (status: boolean) => (restrictedAddress.value = status);

const selected = computed({
  get(): Address {
    return createQuoteStore.quoteRequest.ShippingAddressId
      ? ({ Id: createQuoteStore.quoteRequest.ShippingAddressId } as Address)
      : ({ Id: deliveryAddressesQuote.defaultDeliveryAddressId } as Address);
  },
  set(item: Address) {
    updateIsShippingAddressRestricted(item.RestrictedAddress);
    createQuoteStore.updateQuoteRequest({
      ShippingAddressId: item.Id,
    } as OfferQuoteRequest);
  },
});

const isShippingAddressRestricted = computed(() => {
  return deliveryAddressesQuote.isShippingAddressRestricted;
});

const priceData = computed(() => {
  if (!quoteId.value) {
    return undefined;
  }
  return productPriceInfoStore.priceDataByOfferId(quoteId.value);
});

const hasPrice = computed(() => {
  if (!priceData.value) {
    return '';
  }
  return priceData.value.RuleStatus === 'OK';
});

const isValid = computed(() => {
  return (
    selected.value.Id !== '' && (!isShippingAddressRestricted.value || !restrictedAddress.value)
  );
});

const isBusy = computed(() => {
  return createQuoteStore.isBusy;
});

const product = computed(() => {
  if (!quoteId.value) {
    return undefined;
  }
  return productStore.productById(quoteId?.value);
});

const back = () => {
  modalStore.showModal({
    modalComponent: 'ModalRequestQuote',
    params: { id: quoteId.value, sellerId: sellerId.value, isMarketplace: isMarketplace.value },
    single: true,
  });
  modalStore.closeModal({ modalComponent: 'ModalRequestQuoteSelectAddress' });
};

const submitQuote = async () => {
  const { ReferenceNumber } = createQuoteStore.quoteRequest;
  if (product.value && hasPrice.value) {
    useAdobeLaunchTracking().bidTracking(ReferenceNumber, product.value);
  }
  const { IsSuccess } = await createQuoteStore.submitQuoteRequest();
  if (IsSuccess) {
    modalStore.closeModal();
  }
};

onMounted(() => fetchDeliveryAddress());
</script>
