import {
  Message,
  MessageAttachmentInfo,
  MessageThread,
  MessageThreadPagination,
} from '@/src/core/apim/spec';
import { HybrisMarketplaceMessageAttachmentInfo } from '../types/hybris/HybrisMarketplaceMessageAttachmentInfo';
import { HybrisMarketplaceMessagingThread } from '../types/hybris/HybrisMarketplaceMessagingThread';
import { HybrisMarketplaceMessage } from '../types/hybris/HybrisMarketplaceMessage';
import { HybrisMessagePagination } from '../types/hybris/HybrisMessagePagination';
import { MarketplaceMessagingThread } from '../types/core/MarketplaceMessagingThread';
import { Pagination } from '../types/core/Pagination';
import { dateStringToIsoWithoutMS } from '../services/date-service';
import { mapHybrisMaketplaceMessageToMarketplaceMessage } from './order-mappings';

/**
 * ===== From Marketplace to Hybris =====
 **/
export function mapMessageThreadToHybrisMarketplaceMessagingThread(
  data: MessageThread,
): HybrisMarketplaceMessagingThread {
  return new HybrisMarketplaceMessagingThread({
    createdDate: data.createdDate ?? '0001-01-01T00:00:00',
    locationDisplayName: data.locationDisplayName,
    masterShopName: data.masterShopName,
    messages: data.messages?.map(mapMessageToHybrisMarketplaceMessage),
    pagination: mapMessageThreadPaginationToHybrisMessagePagination(data.pagination),
    subject: data.subject,
    threadId: data.threadId,
    totalMessageCount: data.totalMessageCount,
    updatedDate: data.updatedDate,
  });
}

export function mapMessageToHybrisMarketplaceMessage(data: Message): HybrisMarketplaceMessage {
  return new HybrisMarketplaceMessage({
    attachmentInfos: data.attachmentInfos?.map(
      mapMessageAttachmentInfoToHybrisMarketplaceMessageAttachmentInfo,
    ),
    authorName: data.authorName,
    authorType: data.authorType,
    body: data.body,
    createdDate: data.createdDate,
  });
}

export function mapMessageAttachmentInfoToHybrisMarketplaceMessageAttachmentInfo(
  data: MessageAttachmentInfo,
): HybrisMarketplaceMessageAttachmentInfo {
  return new HybrisMarketplaceMessageAttachmentInfo({
    id: data.id,
    name: data.name,
    size: data.size,
  });
}

export function mapMessageThreadPaginationToHybrisMessagePagination(
  data?: MessageThreadPagination,
): HybrisMessagePagination {
  return new HybrisMessagePagination({
    currentPage: data?.currentPage,
    pageSize: data?.pageSize,
    totalMessageCount: data?.totalMessageCount,
  });
}

/**
 * ===== From Hybris to Marketplace =====
 **/
export function mapHybrisMarketplaceMessagingThreadToMarketplaceMessagingThread(
  data?: HybrisMarketplaceMessagingThread,
): MarketplaceMessagingThread {
  return new MarketplaceMessagingThread({
    createdDate: data?.createdDate,
    locationDisplayName: data?.locationDisplayName,
    masterShopName: data?.masterShopName,
    messages: data?.messages?.map(mapHybrisMaketplaceMessageToMarketplaceMessage) ?? [],
    pagination: mapHybrisMessagePaginationToPagination(data?.pagination),
    subject: data?.subject,
    threadId: data?.threadId,
  });
}

export function mapHybrisMessagePaginationToPagination(data?: HybrisMessagePagination): Pagination {
  return new Pagination({
    currentPage: data?.currentPage,
    totalPages: data?.pageSize,
    totalResults: data?.totalMessageCount,
  });
}
