<template>
  <div class="content-modal-wrapper">
    <ContentBlock :modal="true" :link-key="contentBlockUrl"></ContentBlock>
  </div>
</template>

<style lang="scss" src="./password-hardened-block.scss" scoped></style>

<script lang="ts" setup>
import { computed } from 'vue';
import ContentBlock from '@/src/core/components/ui/content-block/content-block.vue';

interface Props {
  isResetPassword: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isResetPassword: false,
});

const contentBlockUrl = computed(() => {
  if (props.isResetPassword) {
    return 'RequirementsForNewPassword_ModalUrl';
  } else {
    return 'RequirementsForChangedPassword_ModalUrl';
  }
});
</script>
