import { Req, ReqQueue, ReqQueueTypes } from '@/src/core/services/requester';
import { QuoteMessage } from '@/src/core/types/api';
import { MessagingApi } from '@/src/market/api/messagingApi';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSellerQuoteConversationStore = defineStore('sellerQuoteConversationStore', () => {
  const messages = ref<QuoteMessage[]>([]);
  const isBusy = ref<boolean>(false);
  const count = ref<number>(0);

  const messagesUpdated = (payload: { data: QuoteMessage[]; loadMore?: boolean }) => {
    if (!payload.loadMore) {
      messages.value = [];
    }
    count.value = payload.data.length;
    messages.value.unshift(...payload.data);
  };

  const resetMessages = () => {
    messages.value = [];
  };

  const reset = () => {
    messages.value = [];
    isBusy.value = false;
    count.value = 0;
  };

  const getMessages = async (payload: { quoteCode: string }) => {
    isBusy.value = true;

    const { IsSuccess, Data } = await Req(
      { url: MessagingApi.GetQuoteMessages(payload.quoteCode) },
      new ReqQueue(ReqQueueTypes.Default, 'messages'),
      MessagingApi.handleGetQuoteMessages.bind(MessagingApi, {
        quoteCode: payload.quoteCode,
      }),
    );

    if (IsSuccess && Data) {
      messagesUpdated({ data: Data });
      isBusy.value = false;
    }

    return { IsSuccess };
  };

  const postMessage = async (payload: {
    miraklQuoteRequestId: string;
    quoteCode: string;
    data: FormData;
  }) => {
    isBusy.value = true;

    const { IsSuccess } = await Req(
      {
        url: MessagingApi.PostQuoteMessage(payload.quoteCode, payload.miraklQuoteRequestId),
        method: 'POST',
        data: payload.data,
      },
      new ReqQueue(ReqQueueTypes.Default, 'messages'),
      MessagingApi.handlePostQuoteMessage.bind(MessagingApi, {
        quoteCode: payload.quoteCode,
        miraklQuoteRequestId: payload.miraklQuoteRequestId,
        data: payload.data,
      }),
    );

    if (IsSuccess) {
      await getMessages({ quoteCode: payload.quoteCode });
      isBusy.value = false;
    }

    return { IsSuccess };
  };

  return {
    messages,
    isBusy,
    count,
    messagesUpdated,
    resetMessages,
    reset,
    getMessages,
    postMessage,
  };
});
