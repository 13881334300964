export const readFileAsBase64 = async function (file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const result = event.target?.result;
      if (result && typeof result === 'string') {
        const base64 = result.split(',')[1]; // Extract the Base64 part
        resolve(base64);
      } else {
        reject(new Error('Failed to read file as Base64'));
      }
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};
