import { FlightHourService, Procurement } from '@/src/core/apim/spec';
import { TextKey } from '@/src/core/services/text-key';
import { ProcurementType } from '@/src/core/settings/procurement-types';
import { Product } from '@/src/core/types/api';
import { AirbusFHSDetails } from '@/src/core/types/interfaces';
import { Dictionary } from 'vue-router/types/router';

export const filterUnusedProcurements = (procurementType: string) => {
  const unusedTypes = [ProcurementType.Inspection, ProcurementType.Scrapping] as string[];

  return !unusedTypes.includes(procurementType);
};

export const findProcurementTypes = (procurements?: Procurement[]) => {
  if (!procurements) {
    return [];
  }

  const procurementTypes = Object.values(ProcurementType).filter(
    filterUnusedProcurements,
  ) as string[];

  return procurements
    ?.map(({ procurementType }) => procurementType)
    .filter((procurement) => procurement && procurementTypes.includes(procurement!));
};

export const getProcurementByRouteParams = (
  params?: Dictionary<string>,
  procurements?: Procurement[],
) => {
  if (!procurements) {
    return undefined;
  }

  if (params?.serialNr) {
    return procurements.find(
      (procurement) => procurement.serialNumber?.toUpperCase() === params.serialNr.toUpperCase(),
    );
  }

  const procurementType = params?.procurementType
    ? params.procurementType.toUpperCase()
    : ProcurementType.New;

  return procurements.find(
    (procurement) => procurement.procurementType === procurementType && !procurement.serialNumber,
  );
};

export const getFhsProcurementByRouteParams = (
  params: Dictionary<string>,
  procurements: FlightHourService[],
) => {
  if (!procurements) {
    return undefined;
  }

  const procurementType = params.procurementType.toUpperCase();

  return procurements.find((procurement) => procurement.procurementType === procurementType);
};

export const formatProcurementType = (procurementType?: string) => {
  switch (procurementType) {
    case ProcurementType.New:
      return TextKey('UI_PRODUCT_NEW');

    case ProcurementType.Used:
      return TextKey('UI_PRODUCT_USED');

    case ProcurementType.Repair:
      return TextKey('UI_PRODUCT_REPAIR');

    case ProcurementType.Lease:
      return TextKey('UI_PRODUCT_LEASE');

    case ProcurementType.ExchangeFhs:
      return TextKey('UI_PRODUCT_EXCHANGE');
    case ProcurementType.ExchangeNew:
      return TextKey('UI_PRODUCT_EXCHANGE_NEW');
    case ProcurementType.ExchangeUsed:
      return TextKey('UI_PRODUCT_EXCHANGE_USED');

    default:
      return TextKey('UI_PRODUCT_NEW');
  }
};

/**
 * Fetches the first procurement with type 'New' from an array of procurements.
 *
 * @module getNewProcurementType
 * @param {Procurement[]} procurements - Array of procurements to be searched.
 * @returns {Procurement | undefined} - The first procurement with type 'New' or undefined if no procurement with such type or if the input array is undefined or empty.
 * @throws {TypeError} - If 'procurements' is not an array or if one or more of its elements are not of type 'Procurement'.
 *
 */
export const getNewProcurementType = (procurements: Procurement[]) => {
  if (!procurements || !procurements.length) {
    return;
  }
  const newProcurement = procurements.find(
    (procurement) => procurement.procurementType === ProcurementType.New,
  );

  return newProcurement;
};

export const isExchangeType = (procurementType: string) => {
  const exhangeTypes = [
    ProcurementType.Exchange,
    ProcurementType.ExchangeUsed,
    ProcurementType.ExchangeNew,
  ] as string[];

  return exhangeTypes.includes(procurementType);
};

export const isLeaseTool = (procurementType?: string, product?: Product) =>
  procurementType?.toUpperCase() === ProcurementType.Lease && product?.IsTool;

export const isLeasePart = (procurementType?: string, product?: Product) =>
  procurementType?.toUpperCase() === ProcurementType.Lease && product?.AirbusProprietary;

export const isUsedTool = (procurementType?: string, product?: Product) =>
  procurementType?.toUpperCase() === ProcurementType.Used && product?.IsTool;

export const isUsedPart = (procurementType?: string, product?: Product) =>
  procurementType?.toUpperCase() === ProcurementType.Used && product?.AirbusProprietary;

export const getProcurementCondition = (procurement: Procurement) => {
  if (procurement?.procurementType === ProcurementType.Used && procurement?.partStatusCode) {
    return procurement.partStatusCode;
  }

  return formatProcurementType(procurement?.procurementType);
};

export const mapFhsToProcurement = (fhsProcurements: AirbusFHSDetails[]): Procurement[] => {
  return fhsProcurements.map((fhs) => {
    return {
      availableStock: 0,
      availabilityDate: '',
      batchNumber: '',
      certificateDate: '',
      certificateSource: '',
      certificateTrackingNumber: '',
      certificateType: '',
      discountEndDate: '',
      discountPercentage: '',
      documentInfos: [],
      equipmentNumber: '',
      eroPart: false,
      leadTimeInDays: 0,
      locationInfos: fhs.locations.map((location, index) => {
        return {
          availableStock: 0,
          sortIndex: index,
          warehouseLocationDescription: location,
          warehouseLocationInitials: location,
        };
      }),
      manufactureDate: '',
      partExpirationDate: '',
      partStatusCode: '',
      priceBreakInfos: [],
      priceUnitFactor: 0,
      procurementType: fhs.procurementType,
      serialNumber: '',
      shelfLifeExpirationDate: '',
      sortIndex: 0,
      sourceName: '',
      supplier: '',
      unknownDeliveryDate: false,
      warehouseLocationDescription: '',
      warehouseLocationInitials: '',
    };
  });
};
