<template>
  <StandardDialogModal :size="uiSize.Medium">
    <template #header>
      <ModalLeasePriceHeader :product="product" />
    </template>
    <template #body>
      <LeasePricingDetailsTable :lease-info="procurement?.leaseInfo" :product="product" />
    </template>
  </StandardDialogModal>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import { UiSize } from '@/src/core/components/ui/ui.types';
import { useModalStore } from '@/src/core/stores/modal';
import { Product } from '@/src/core/types/api';
import { Procurement } from '@/src/core/types/interfaces';
import ModalLeasePriceHeader from '@/src/market/components/modals/modal-lease-price/modal-lease-price-header/modal-lease-price-header.vue';
import LeasePricingDetailsTable from '@/src/market/components/lease/lease-pricing-details-table/lease-pricing-details-table.vue';

interface Params {
  procurement: Procurement;
  product: Product;
}

const uiSize = UiSize;
const modalStore = useModalStore();

const procurement = computed(() => {
  return (modalStore.params as Params)?.procurement;
});

const product = computed(() => {
  return (modalStore.params as Params)?.product;
});
</script>

<style lang="scss" src="./modal-lease-price.scss" scoped></style>
