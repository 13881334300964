<template>
  <StandardDialogModal :size="uiSize.Medium">
    <template #header>
      <h3>{{ textK('UI_SERVICE_ORDERS_REPAIR_EXCHANGE_MODAL_TITLE') }}</h3>
    </template>
    <template #body>
      <p>
        <span v-html="descriptionPartOne"></span>
        (
        <span v-if="isFetchingOrders" class="loader"
          ><DotLoader :loading="true" to-color="#000"
        /></span>
        <span v-else
          ><b>{{ total }}</b></span
        >
        )
        <span v-html="descriptionPartTwo"></span>
      </p>
      <div class="actions">
        <VButton
          class="btn"
          :loading="isDownLoadingCsv || isFetchingOrders"
          :disabled="isDownLoadingExcel"
          :type="uiVariant.Secondary"
          @click.prevent="exportAsCSV"
          v-ui-test="'export-as-csv'"
        >
          {{ textK('UI_SERVICE_ORDERS_REPAIR_EXCHANGE_MODAL_EXPORT_CSV') }}
        </VButton>
        <VButton
          class="btn"
          :loading="isDownLoadingExcel || isFetchingOrders"
          :disabled="isDownLoadingCsv"
          :type="uiVariant.Secondary"
          @click.prevent="exportAsExcel"
          v-ui-test="'export-as-excel'"
        >
          {{ textK('UI_SERVICE_ORDERS_REPAIR_EXCHANGE_MODAL_EXPORT_EXCEL') }}
        </VButton>
      </div>
    </template>
  </StandardDialogModal>
</template>

<style src="./modal-export-repair-exchange-orders.scss" lang="scss" scoped></style>

<script lang="ts" setup>
import { OrderApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import DotLoader from '@/src/core/components/ui/ani-icons/dot-loader/dot-loader.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import useText from '@/src/core/hooks/useText';
import { useDownloadsStore } from '@/src/core/stores/downloads';
import { ServiceOrderRequestParams } from '@/src/core/types/api';
import { toHybrisFormat } from '@/src/core/utils/dates';
import { useRepairExchangeOrdersStore } from '@/src/market/stores/repair-exchange-orders';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

const uiVariant = UiVariant;
const uiSize = UiSize;
const textK = useText();

const downloadStore = useDownloadsStore();
const repairStore = useRepairExchangeOrdersStore();
const { filters } = storeToRefs(repairStore);

const isDownLoadingCsv = ref(false);
const isDownLoadingExcel = ref(false);
const descriptionPartOne = ref<string>('');
const descriptionPartTwo = ref<string>('');

const params = computed<ServiceOrderRequestParams>(() => {
  const newFilters = {
    ...filters.value,
    fromDate: filters.value.fromDate || toHybrisFormat(dayjs().subtract(12, 'month')),
    toDate: filters.value.toDate || toHybrisFormat(dayjs()),
    status: filters.value.status || 'ALL',
  };
  delete newFilters.timePeriod;
  return newFilters;
});

const total = computed(() => repairStore.orders.length);

const isFetchingOrders = computed(() => repairStore.isBusy);

const exportAsCSV = async () => {
  isDownLoadingCsv.value = true;
  await downloadFile('CSV');
  isDownLoadingCsv.value = false;
};

const exportAsExcel = async () => {
  isDownLoadingExcel.value = true;
  await downloadFile('EXCEL');
  isDownLoadingExcel.value = false;
};

const downloadFile = async (format: 'CSV' | 'EXCEL') => {
  return await downloadStore.downloadFile(
    {
      url: OrderApi.ExportRepairExchangeOrders,
      method: 'POST',
      params: { fileFormat: format },
      data: params.value,
    },
    undefined,
    OrderApi.handleExportAirbusServiceOrders,
  );
};

onMounted(() => {
  const text = textK('UI_SERVICE_ORDERS_REPAIR_EXCHANGE_MODAL_DESCRIPTION').split('[[number]]');
  descriptionPartOne.value = text[0];
  descriptionPartTwo.value = text[1];
});
</script>
