import { FlightHourService, Procurement } from '@/src/core/apim/spec';
import router from '@/src/core/router';
import { useNotificationsStore } from '@/src/core/stores/notifications';
import { NotificationTypes } from '@/src/core/types/api';
import {
  getFhsProcurementByRouteParams,
  getNewProcurementType,
  getProcurementByRouteParams,
} from '@/src/market/services/procurements';

export const redirectToCurrentPathWithoutProcurement = () => {
  let updatedRoute = window.location.pathname;
  const { currentRoute } = router;

  updatedRoute = updatedRoute.replace(`/${currentRoute.value.params.serialNr}`, '');
  updatedRoute = updatedRoute.replace(`/${currentRoute.value.params.procurementType}`, '');

  if (currentRoute.value.path !== updatedRoute) {
    router.push(updatedRoute);
  }
};

export const redirectToSaleNew = async (
  marketOfferUrl: string | null,
  procurements?: Procurement[],
) => {
  if (procurements && procurements.length > 0) {
    const saleNew = getNewProcurementType(procurements);

    if (saleNew?.procurementType && marketOfferUrl) {
      const path = `${marketOfferUrl}/${saleNew.procurementType.toLowerCase()}`;

      if (router.currentRoute.value.fullPath !== path) {
        const routerOptions = {
          path,
        };
        router.push(routerOptions);
      }
    }
  }
};

export const handleProcurementUrlRedirects = async (
  procurements?: Procurement[],
  isFhs?: boolean,
  fhsProcurements?: FlightHourService[],
) => {
  const notificationsStore = useNotificationsStore();
  const { params } = router.currentRoute.value ?? {};

  if (isFhs && fhsProcurements && fhsProcurements?.length > 0) {
    const fhsProcurement = getFhsProcurementByRouteParams(params, fhsProcurements);

    if (!fhsProcurement) {
      notificationsStore.addNotification({
        notificationItem: {
          Title: 'Product variant not found',
          Description: 'We could not find the variant of the product you looked for.',
          Type: NotificationTypes.Warning,
          Timing: 5000,
        },
      });
      router.push('/market/productnotfound');
    }
    return;
  }

  // if product has no procurements then it's outside user's catalogue
  // show restricted access
  if (!procurements || procurements.length === 0) {
    redirectToCurrentPathWithoutProcurement();
    return;
  }

  const procurement = getProcurementByRouteParams(params, procurements);

  // if procurement doesn't exist
  // or if there is no serialNr param for serialized procurement and not fhs
  // redirect to sales_new
  if (!procurement || (procurement && procurement.serialNumber && !params.serialNr)) {
    const serialNr = encodeURIComponent(params.serialNr).toLowerCase();
    const procurementType = encodeURIComponent(params.procurementType).toLowerCase();
    let updatedRoute = window.location.pathname.toLowerCase();
    updatedRoute = updatedRoute.replace(`/${serialNr}`, '');
    updatedRoute = updatedRoute.replace(`/${procurementType}`, '/sale_new');
    notificationsStore.addNotification({
      notificationItem: {
        Title: 'Product variant not found',
        Description: 'We could not find the variant of the product you looked for.',
        Type: NotificationTypes.Warning,
        Timing: 5000,
      },
    });
    router.push(updatedRoute);
    return;
  }

  if (params.serialNr) {
    // if procurement is not serialized
    // delete serial number from route
    if (!procurement?.serialNumber && params.serialNr) {
      let updatedRoute = window.location.pathname;
      updatedRoute = updatedRoute.replace(`/${params.serialNr}`, '');
      router.push(updatedRoute);
      return;
    }

    // if procurement serial number and param don't match
    // redirect to sales_new
    const serialProcurement = procurement?.serialNumber;
    const serialRoute = decodeURIComponent(params.serialNr);

    if (serialProcurement?.toUpperCase() !== serialRoute.toUpperCase()) {
      const serialNr = encodeURIComponent(params.serialNr).toLowerCase();
      const procurementType = encodeURIComponent(params.procurementType).toLowerCase();
      let updatedRoute = window.location.pathname.toLowerCase();
      updatedRoute = updatedRoute.replace(`/${serialNr}`, '');
      updatedRoute = updatedRoute.replace(`/${procurementType}`, '/sale_new');

      notificationsStore.addNotification({
        notificationItem: {
          Title: 'Product variant not found',
          Description: 'We could not find the variant of the product you looked for.',
          Type: NotificationTypes.Warning,
          Timing: 5000,
        },
      });

      router.push(updatedRoute);
    }
  }
};
