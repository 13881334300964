import { HybrisMarketplaceMessageAttachmentInfo } from './HybrisMarketplaceMessageAttachmentInfo';

export interface IHybrisQuoteMessage {
  body: string;
  createdDate: string;
  fromType: string;
  id: string;
  toType: string;
  attachmentInfos: HybrisMarketplaceMessageAttachmentInfo[];
}

export class HybrisQuoteMessage implements IHybrisQuoteMessage {
  body: string;
  createdDate: string;
  fromType: string;
  id: string;
  toType: string;
  attachmentInfos: HybrisMarketplaceMessageAttachmentInfo[];

  constructor(init?: Partial<HybrisQuoteMessage>) {
    Object.assign(this, init);
  }
}
