<template>
  <form @submit.prevent="changePassword">
    <StandardDialogModal :has-cancel-button="true" :size="uiSize.Medium">
      <template #header>
        <div class="typeset">
          <h3>Change password</h3>
        </div>
      </template>
      <template #body>
        <div class="typeset">
          <div class="input-container">
            <VTextField
              autocomplete="current-password"
              v-ui-test="'changepassword-oldpassword'"
              :type="uiVariant.Secondary"
              :label="isHardenPasswordPolicyEnabled ? 'Old password' : 'Password'"
              input-type="password"
              name="oldPassword"
              v-model="oldPassword"
              disable-type-validation
            ></VTextField>
          </div>
          <div class="input-container">
            <VTextField
              autocomplete="new-password"
              v-ui-test="'changepassword-newpassword'"
              :type="uiVariant.Secondary"
              label="New password"
              :input-type="isShowNewPassword ? 'text' : 'password'"
              name="newPassword"
              v-model="newPassword"
              disable-type-validation
              :error-message="isHardenPasswordPolicyEnabled ? errorMessage : null"
            >
              <template v-if="isHardenPasswordPolicyEnabled" #append>
                <svgicon
                  v-if="!isShowNewPassword"
                  @click="toggleShowNewPassword"
                  class="password_icon password_icon--show"
                  name="ui_show_password_eye"
                ></svgicon>
                <svgicon
                  v-else
                  @click="toggleShowNewPassword"
                  class="password_icon password_icon--hide"
                  name="ui_hide_password_eye"
                ></svgicon>
              </template>
            </VTextField>
          </div>
          <div class="input-container" v-if="!isHardenPasswordPolicyEnabled">
            <VTextField
              autocomplete="new-password"
              v-ui-test="'changepassword-newpassword-retyped'"
              :type="uiVariant.Secondary"
              label="Repeat new password"
              input-type="password"
              name="reapeatNewPassword"
              v-model="repeatNewPassword"
              disable-type-validation
            ></VTextField>
          </div>
          <template v-if="isHardenPasswordPolicyEnabled">
            <PasswordHardenedBlock></PasswordHardenedBlock>
          </template>

          <PasswordValidationBox v-else :value="newPassword" :repeated-value="repeatNewPassword">
          </PasswordValidationBox>
        </div>
      </template>
      <template #actions>
        <VButton
          :disabled="disableSubmit"
          :type="uiVariant.Default"
          :loading="isBusy"
          v-ui-test="'changepassword-confirm-button'"
          >Save
        </VButton>
      </template>
    </StandardDialogModal>
  </form>
</template>

<style lang="scss" src="./modal-change-password.scss" scoped></style>

<script lang="ts" setup>
import { UserApi } from '@/src/core/api';
import StandardDialogModal from '@/src/core/components/modals/ui/standard-dialog/StandardDialogModal.vue';
import VButton from '@/src/core/components/ui/button/button.vue';
import PasswordHardenedBlock from '@/src/core/components/ui/form/password-hardened-block/password-hardened-block.vue';
import PasswordValidationBox from '@/src/core/components/ui/form/password-validation-box/password-validation-box.vue';
import VTextField from '@/src/core/components/ui/form/text-field/text-field.vue';
import { UiSize, UiVariant } from '@/src/core/components/ui/ui.types';
import { useHardenedPasswordPolicy } from '@/src/core/hooks/useHardenedPassworldPolicy';
import { Req } from '@/src/core/services/requester';
import { useModalStore } from '@/src/core/stores/modal';
import { IUser } from '@/src/core/types/interfaces';
import { passwordRegEx } from '@/src/core/utils/regex-library';
import { useUserStore } from '@/src/profile/stores/user';
import { computed, onMounted, ref, watch } from 'vue';

const modalStore = useModalStore();
const userStore = useUserStore();

const uiVariant = UiVariant;
const uiSize = UiSize;

const oldPassword = ref('');
const newPassword = ref('');
const repeatNewPassword = ref('');
const userData = ref<IUser>({} as IUser);
const isShowNewPassword = ref(false);
const isBusy = ref(false);

const currentUserData = computed(() => userStore.currentUser);
const customerUid = computed(() => currentUserData.value.Uid);

const modalParams = computed(() => modalStore.params as IUser);
const isHardenPasswordPolicyEnabled = computed(() => userStore.isHardenPasswordPolicyEnabled);
const passwordFormValues = computed(() => {
  return {
    oldPassword: oldPassword.value,
    newPassword: newPassword.value,
    email: customerUid.value,
  };
});

const { isValid, errorMessage } = useHardenedPasswordPolicy(passwordFormValues);

const disableSubmit = computed(() => {
  if (isHardenPasswordPolicyEnabled.value) {
    return !isValid.value;
  }
  return (
    !passwordRegEx.test(newPassword.value) ||
    oldPassword.value.length === 0 ||
    newPassword.value.length === 0 ||
    newPassword.value !== repeatNewPassword.value
  );
});

const changePassword = async () => {
  if (userData.value.GlobalId) {
    isBusy.value = true;
    const { IsSuccess } = await Req({
      url: UserApi.UpdateUserPassword(userData.value.GlobalId),
      method: 'POST',
      data: { password: oldPassword.value, newpassword: newPassword.value },
    });
    if (IsSuccess) {
      modalStore.closeModal();
    }
    isBusy.value = false;
  }
};

const toggleShowNewPassword = () => {
  isShowNewPassword.value = !isShowNewPassword.value;
};

watch(
  () => currentUserData,
  () => {
    modalStore.closeModal();
  },
);

onMounted(() => {
  userData.value = modalParams.value || ({} as IUser);
});
</script>
