import { MarketplaceMessageAttachmentInfo } from './MarketplaceMessageAttachmentInfo';

export class MarketplaceMessage {
  authorName?: string;
  authorType?: string;
  body?: string;
  createdDate?: string; // ISO 8601 string
  subject?: string;
  attachmentInfos?: MarketplaceMessageAttachmentInfo[];

  constructor(init?: Partial<MarketplaceMessage>) {
    Object.assign(this, init);
  }
}
