<template>
  <li v-if="condition" class="product-details-list-item">
    <div class="product-details-list-item-content">
      <p class="product-details-list-item-title product-details-title-label">
        {{ textK('UI_PRODUCT_CONDITION') }}
      </p>
      <p class="product-details-list-item-value">
        {{ condition }}
      </p>
    </div>
  </li>
</template>

<script lang="ts" setup>
import useText from '@/src/core/hooks/useText';
import { getRouteParamAsString } from '@/src/core/utils/router';
import {
  formatProcurementType,
  getProcurementByRouteParams,
  getProcurementCondition,
} from '@/src/market/services/procurements';
import { isAirbus, productAdditionalData } from '@/src/market/services/product-parts';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

interface Props {
  id: string;
  owningSystem: string;
  offerState?: string;
  isFhs?: boolean;
}

const route = useRoute();
const props = defineProps<Props>();
const textK = useText();

const productData = computed(() => {
  return productAdditionalData(props.id, props.owningSystem);
});

const condition = computed(() => {
  if (props.isFhs) {
    const { params } = route;
    const procurementType = getRouteParamAsString(params.procurementType)?.toUpperCase();
    return formatProcurementType(procurementType);
  }

  if (isAirbus(productData.value) && procurementData.value) {
    return getProcurementCondition(procurementData.value);
  }

  return props.offerState || 'New';
});

const procurementData = computed(() => {
  if (isAirbus(productData.value)) {
    const { params } = route;
    return getProcurementByRouteParams(params, productData.value.procurements);
  }

  return null;
});
</script>
