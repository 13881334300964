import { ProductApi } from '@/src/core/api';
import { Req } from '@/src/core/services/requester';
import { AIRBUS_FHS_SELLER_KEY, AIRBUS_SELLER_KEY } from '@/src/market/utils/seller-key';

export async function getProductSkuAsync(sku: string) {
  const { IsSuccess, Data } = await Req(
    {
      url: ProductApi.ProductSku(),
      params: {
        sku,
      },
      headers: { BypassNotifications: true },
    },
    undefined,
    ProductApi.handleProductSku.bind(ProductApi, sku),
  );

  if (IsSuccess) {
    return Data;
  } else {
    return undefined;
  }
}

export async function getProductOfferAsync(id: string) {
  const { IsSuccess, Data } = await Req(
    {
      url: ProductApi.ProductOffer(id),
      headers: { BypassNotifications: true },
    },
    undefined,
    ProductApi.handleProductOffer.bind(ProductApi, id),
  );

  if (IsSuccess) {
    return Data;
  } else {
    return undefined;
  }
}

export async function getProductInfoAsync(id: string) {
  const { IsSuccess, Data } = await Req(
    {
      url: ProductApi.ProductInfo(id),
    },
    undefined,
    ProductApi.handleProductInfo.bind(ProductApi, id),
  );

  if (IsSuccess) {
    return Data;
  }
}

export const isAirbusProduct = (owningSystem?: string): boolean => {
  return owningSystem?.toLowerCase() === 'blue';
};

/**
 * Convert the Airbus MarketOfferUrl to AirbusFhs
 *
 * @param url
 * @returns string
 */
export const transformAirbusLinkToFhs = (url: string) => {
  const airbusLink = url?.includes(AIRBUS_SELLER_KEY);
  if (airbusLink) {
    return url.replace(AIRBUS_SELLER_KEY, AIRBUS_FHS_SELLER_KEY);
  }
  return url;
};

/**
 * Convert airbusfhs text to airbus
 *
 * @param text
 * @returns string
 */
export const transformAirbusFhsToAirbus = (text: string) => {
  const airbusFhs = text?.includes(AIRBUS_FHS_SELLER_KEY);
  if (airbusFhs) {
    return text.replace(AIRBUS_FHS_SELLER_KEY, AIRBUS_SELLER_KEY);
  }
  return text;
};
